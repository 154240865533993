import React, { useEffect } from "react";
import MainDashboard from "./main_dash/MainDashboard";
import Header from "../../components/header/Header";
import ResourceList from "./rightbar/ResourceList";
import CurrentPlan from "./rightbar/CurrentPlan";
// import Result from "../"
import { useAnalysisContext } from "../../context/AnalysisContext";


import css from "./dashboard.module.css";
import SetJobTitleCard from "../../components/header/SetJobTitleCard";
import Result from "../../components/video_result/Result";

const Dashboard = () => {
  // const { sendAnalysisTitle, takeInterviewTitle, takeInterviewTitleChange } =
  //   useAnalysisContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex">
      <div className=" flex flex-col w-full ">
        <Header title="Dashboard" size={2} />
        <div className={css.dashboard}>
          {/* only show this title and Jobcard in small screen, hidden from md: and lg: screen */}
          <h2 className={css.mobile_title}>Dashboard</h2>
          <div className="md:hidden">
            <SetJobTitleCard />
          </div>

          <div className={css.main_dashboard_wrapper}>
            <MainDashboard />
          </div>
          <div className={css.dashboard_rightbar}>
            <div className="flex flex-col ">
              <ResourceList />
              <div className=" my-4"></div>
              <CurrentPlan />
            </div>
          </div>
        </div>
      </div>
     <Result /> 
    </div>
  );
};

export default Dashboard;
