import React, { useEffect } from "react";
import VideoAudioAnalysis from "../video_analysis/VideoAudioAnalysis";
import Result from "../../../components/video_result/Result";
const audioData = [
  {
    title: "Tone of Voice",
    score: "0",
  },
  {
    title: "Choice of Words",
    score: "0",
  },
  {
    title: "Level of Pitch",
    score: "0",
  },
  {
    title: "Level of Confidence",
    score: "0",
  },
];

const AudioAnalysis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <VideoAudioAnalysis analysisData = {audioData} title = "Voice Analysis"  />
    <Result />
    </>
  );
};

export default AudioAnalysis;
