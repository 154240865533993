import React from "react";
import { Link } from "react-router-dom";
import { useAnalysisContext } from "../../../context/AnalysisContext";

const SampleCard = ({ category, title }) => {
  //To send the Job title to backend, so that question should be
  //asked according to that title
  const { sendAnalysisTitle } = useAnalysisContext();

  return (
    <div className=" shadow-lg rounded-2xl p-3 bg-white w-72">
      <button className=" py-2 px-4 rounded-full bg-gray-200 hover:bg-gray-300">
        {category}
      </button>
      <h3 className=" font-medium text-lg my-2 ">{title}</h3>
      <div className=" flex justify-center items-center">
        <button className="bg-yellow-200 hover:bg-yellow-300 rounded-full px-20 py-1 font-semibold">
          <Link
            onClick={() => sendAnalysisTitle({ interviewTitle: category })}
            to="/dashboard/takeAnalysis"
          >
            Practice Now
          </Link>
        </button>
      </div>
    </div>
  );
};

export default SampleCard;
