import React from "react";
// import TemplatesCard from "../TemplatesCard";

const HR = () => {
  const category = "HR";
  // const hrData = [
  //   { title: "Interview Of Junior HR Officer" },
  //   { title: "Interview Of Senior HR Officer" },
  //   { title: "Interview Of Mid Level HR Officer" },
  //   { title: "Interview Of Expert HR Officer" },
  //   { title: "Interview Of Junior HR Officer 2" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {hrData} />
    <strong>{category}</strong>

  );
};

export default HR;
