import React, { useEffect } from "react";
// import Header from "./../../components/header/Header";
// import Sidebar from "../../components/sidebar/Sidebar";
import dashboardIcons from "../../resources";
import { Link } from "react-router-dom";

import css from './error.module.css'
const ErrorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex h-screen">
      {/* <div className={css.error_sidebar}>
        <Sidebar />
      </div> */}
      <div className={css.error_wrapper}>
        {/* <Header title="Oops..." size={1.75} /> */}
         <h2 className={css.mobile_title}>Contact Support</h2>
        <div className={css.error_content}>
          <div
            className = {css.error_circle}
            // className=" flex flex-col justify-center items-center gap-3 bg-gradient-to-b from-yellow-200 to-white"
          >
            <div className=" flex items-center gap-2">
              <span className="text-8xl font-semibold text-gray-900">4</span>
              <img
                src={dashboardIcons.icons.logo}
                className=" w-20 h-20"
                alt="LOGO"
              />
              <span className="text-8xl font-semibold text-gray-900">4</span>
            </div>
            <p className=" text-lg font-medium">Sorry, Page Not Found</p>
            <Link
              to="/dashboard"
              className="p-2 px-6 rounded-full text-lg font-semibold bg-gradient-to-t from-yellow-300 to-white transform hover:scale-105 transition duration-300"
            >
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
