import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

// import dashboardIcons from '../../resources'

const TypeWriter = () => {

  return (
    <div className=' flex flex-col items-center gap-4 my-8 px-4'>
      <h4 className='text-xl font-medium font-mono text-center '>
      Hi I want to become a{' '}
        <span className=' font-mono font-bold' style={{ color: '#35B276' }}>
          <Typewriter
            words={['Software Engineer', 'Investment Banker', 'Doctor', 'Lawyer']}
            loop={0}
            cursor
            cursorStyle='_ '
            typeSpeed={100}
            deleteSpeed={70}
            delaySpeed={1000}
          />
        </span>
        can you help me prepare?
      </h4>

      {/* <img src={dashboardIcons.landing.interview_demo} alt='DEMO' /> */}
    </div>
  )
}

export default TypeWriter