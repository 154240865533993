import React from "react";
import dashboardIcons from "../resources/index";
import { Link } from "react-router-dom";
import css from './landing.module.css';

const LandingFooter = () => {
  return (
    <div className={css.footer}>
      <div className={css.footer_inner}>
        <a href="/" className={css.logo}>
          <img
            src={dashboardIcons.icons.logo}
            alt="Pluuo AI"
          />
          <span>Pluuo AI</span>
        </a>
        <div>
          <p className={css.date}>&copy;{new Date().getFullYear()} Pluuo AI</p>
        </div>
      </div>
      <div className={css.links}>
        <Link to="/signup">Pricing</Link>
        <Link to="/signup">Resources</Link>
        <Link to="/signup">Product</Link>
        <Link to="/support">Support</Link>
      </div>
    </div>
  );
};

export default LandingFooter;
