import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ActionBtn from "./ActionBtn";
import { useHistoryContext } from "../../context/HistoryDataContext";

const Record = () => {
  const { prevScores } = useHistoryContext();
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: " #1F2937", //bg-gray-800
      color: "#ffffff",
      fontSize: 16,
      font: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F3F4F6", //bg-gray-100
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // const rows = [
  //   {
  //     name: "UI/UX Interview Demo",
  //     template: "UI/UX",
  //     date: "30 June 2023",
  //     score: "87",
  //   },
  //   {
  //     name: "HR Interview Demo",
  //     template: "HR",
  //     date: "30 June 2023",
  //     score: "44",
  //   },
  //   {
  //     name: "Project Manager Interview Demo",
  //     template: "Project Manager",
  //     date: "29 June 2023",
  //     score: "62",
  //   },
  //   {
  //     name: "Backend Interview Demo",
  //     template: "Backend",
  //     date: "28 June 2023",
  //     score: "76",
  //   },
  //   {
  //     name: "Frontend Interview Demo",
  //     template: "Frontend",
  //     date: "26 June 2023",
  //     score: "92",
  //   },
  // ];

  return (
    <TableContainer component={Paper} className=" rounded-full">
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            {/* <StyledTableCell align="left">Templates</StyledTableCell> */}
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Score</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prevScores.map((score, index) => {
            const tempScore = score["body language"] * 100;
            const rawDate = new Date(score.date);
            const formattedDate = rawDate.toDateString().slice(4);
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {score.jobTitle}
                </StyledTableCell>
                {/* <StyledTableCell align="left">{row.template}</StyledTableCell> */}
                <StyledTableCell align="left">{formattedDate}</StyledTableCell>
                <StyledTableCell align="left">{tempScore}%</StyledTableCell>
                <StyledTableCell align="left">
                  <ActionBtn
                    tempScore={tempScore}
                    formattedDate={formattedDate}
                    jobTitle={score.jobTitle}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Record;
