import React from "react";
import ResourceList from './../../dashboard/rightbar/ResourceList';

const RHR = () => {
  const category = "HR";
  
  return (
    <div className="flex flex-wrap gap-4 justify-center items-center ">
      <h3 className=" text-gray-800 font-bold text-2xl" >{category} Resources are here</h3>
      <ResourceList video_audio = {true} />
      <ResourceList video_audio = {true} />
    </div>
  );
};

export default RHR;
