import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import css from "./takeAnalysis.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        className=" flex justify-between items-center text-gray-600"
        // className={css.dialog_nav}
      >
        <h3 className=" font-semibold text-xl">{children}</h3>
        {onClose ? (
          <div
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <button className=" bg-gray-800 hover:bg-gray-950 text-gray-100 text-lg px-4 rounded-full ml-4">
              Close
            </button>
          </div>
        ) : null}
      </div>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogCard = ({ hideResult, loading, videoSize }) => {
  const handleClose = () => {
    hideResult();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Analysis Scores On Progress ...
        </BootstrapDialogTitle>
        <div className={css.result_wrapper}>
          <div className=" flex flex-col justify-between py-8 items-center w-full">
            <h3 className=" text-xl text-gray-800 font-semibold text-center mb-4">
              Be Patient! We are Analysing your response.
            </h3>
            <h5 className="text-gray-700 font-semibold text-center mb-8">
              You can Close this. We let you know as your scores are ready!
            </h5>
            <div className="custom-loader "></div>
            <p>
              Video Size is <strong>{videoSize}kb</strong>
            </p>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default DialogCard;
