import React from "react";
// import TemplatesCard from "../TemplatesCard";

const Backend = () => {
  const category = "Backend";
  // const backendData = [
  //   { title: "Interview Of Junior Backend Officer" },
  //   { title: "Interview Of Senior Backend Officer" },
  //   { title: "Interview Of Mid Level Backend Officer" },
  // ];

  return (
    // <TemplatesCard category = {category} dataList = {backendData} />
    <strong>{category}</strong>
  
    );
};

export default Backend;
