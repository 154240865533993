import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { useAnalysisContext } from "../../../context/AnalysisContext";

import css from "../dashboard.module.css";

import {
  ArrowForwardIos,
  ArrowBackIos,
  RadioButtonChecked,
  PlayCircleOutline,
  TaskAlt,
  Replay,
} from "@mui/icons-material";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const VideoRecord = ({ loadingTrue, loadingFalse }) => {
  const { setAnalysisScores } = useAnalysisContext(); //storing the analysis scores in the context
  const [isPlaying, setIsPlaying] = useState(false); //indicates the audio question
  const [showRetake, setShowRetake] = useState(false); //Retake Recoding Button

  //Recorded Video dimentions, download type and name
  const recordWebcam = useRecordWebcam({
    mimeType: "video/mp4",
    fileName: "PluuoAI interview",
    // width: { min: 480 },
    // height: { min: 720 },
    width: { min: 1200 },
  height: { min: 920 },
    aspectRatio: 1.4

  });

  //As we click on startRecord, go to full screen
  const handleFullScreen = () => {
    const videoElement = document.getElementById("record_wrapper");

    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  const playAudioQ = async () => {
    try {
      const response = await fetch("https://pluuo-backend-v2-237533y6va-uc.a.run.app/start");
      if (response.ok) {
        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        setIsPlaying(true); // Set the state to indicate that audio question is playing
        const audio = new Audio(audioUrl);
        audio.play(); // Play the audio Question
        audio.onended = () => {
          setIsPlaying(false); // Set the state to indicate that audio has stopped playing
          URL.revokeObjectURL(audioUrl); // Release the object URL after audio playback
        };
      } else {
        console.error("Failed to start recording on the backend.");
      }
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startRecording = () => {
    recordWebcam.open();
    setShowRetake(false);
    handleFullScreen();
  };

  useEffect(() => {
    if (recordWebcam.status === "OPEN") {
      recordWebcam.start();
    }
  }, [recordWebcam.status]);

  // //As the Video recoding start, getting the audio question from the backend
  // const handleStartRecording = async () => {
  //   recordWebcam.start();
  //   try {
  //     const response = await fetch(
  //       // "http://127.0.0.1:5000/start"
  //       // "https://pluuo-backend-fc92c6b72668.herokuapp.com/start"
  //       "https://api.pluuo.com/start"
  //     );
  //     if (response.ok) {
  //       const audioBlob = await response.blob();
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       setIsPlaying(true); // Set the state to indicate that audio question is playing
  //       const audio = new Audio(audioUrl);
  //       audio.play(); // Play the audio Question
  //       audio.onended = () => {
  //         setIsPlaying(false); // Set the state to indicate that audio has stopped playing
  //         URL.revokeObjectURL(audioUrl); // Release the object URL after audio playback
  //       };
  //     } else {
  //       console.error("Failed to start recording on the backend.");
  //     }
  //   } catch (error) {
  //     console.error("Error starting recording:", error);
  //   }
  // };

  //sending the recorded video to backend and getting some analysis scores
  const handleRecordingComplete = async () => {
    recordWebcam.stop();
    loadingTrue();

    // Exit fullscreen
    try {
      // Check if the document is in fullscreen before trying to exit
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    } catch (error) {
      console.error("Error while exiting fullscreen:", error);
    }
    // Add a delay of 500ms (0.2s seconds) to get the complete vidoe
    await new Promise((resolve) => setTimeout(resolve, 200));
    setShowRetake(true);
    const videoBlob = await recordWebcam.getRecording();
    console.log("Recorded Video Blob:", videoBlob);

    const formData = new FormData();

    formData.append("video", videoBlob, "recorded_video.mp4");
    try {
      const response = await fetch(
        // "http://127.0.0.1:5000/check",
        // "https://pluuo-backend-fc92c6b72668.herokuapp.com/check",
        "https://pluuo-backend-v2-237533y6va-uc.a.run.app/check",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Parse the response JSON
        console.log("Resonse that we are g", responseData);
        setAnalysisScores(responseData);
        loadingFalse();
      } else {
        console.error("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      loadingFalse();
      setShowRetake(true);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 text-center">
        <h1 className="text-2xl font-bold">Pluuo AI Interview</h1>
        {/* <button onClick={handleFullScreen}>Go Full Screen</button> */}
        {/* <p className="text-gray-800 font-semibold my-2">
          Camera status:{" "}
          <span className=" font-semibold text-yellow-300 bg-slate-900 p-1 rounded-md">
            {recordWebcam.status}
          </span>
        </p> */}
        {isPlaying && (
          <p className="text-gray-800 bg-yellow-200 p-2 rounded font-bold text-xl mt-2 animate-blinking">
            Listen the Question Carefully and answer it!!
          </p>
        )}
      </div>
      {/* <div className="flex gap-2 mb-4">
        <div className="flex gap-2">
          <button
            className={`px-4 py-2 rounded-full bg-yellow-200  ${
              (recordWebcam.status === "OPEN" ||
                recordWebcam.status === "RECORDING" ||
                recordWebcam.status === "PREVIEW") &&
              "bg-gray-300 text-gray-500 font-light cursor-not-allowed"
            }`}
            disabled={
              recordWebcam.status === "OPEN" ||
              recordWebcam.status === "RECORDING" ||
              recordWebcam.status === "PREVIEW"
            }
            onClick={recordWebcam.open}
          >
            Open camera
          </button>
          <button
            className={`px-4 py-2 rounded-full bg-yellow-200 ${
              recordWebcam.status === "CLOSED" &&
              "bg-gray-300 text-gray-500 font-light cursor-not-allowed"
            }`}
            disabled={recordWebcam.status === "CLOSED"}
            onClick={recordWebcam.close}
          >
            Close camera
          </button>
          <button
            className={`px-4 py-2 rounded-full bg-yellow-200 ${
              (recordWebcam.status === "CLOSED" ||
                recordWebcam.status === "INIT" ||
                recordWebcam.status === "RECORDING" ||
                recordWebcam.status === "PREVIEW") &&
              "bg-gray-300 text-gray-500 font-light cursor-not-allowed"
            }`}
            disabled={
              recordWebcam.status === "CLOSED" ||
              recordWebcam.status === "INIT" ||
              recordWebcam.status === "RECORDING" ||
              recordWebcam.status === "PREVIEW"
            }
            onClick={handleStartRecording}
          >
            Start recording
          </button>
          <button
            className={`px-4 py-2 rounded-full bg-yellow-200 ${
              (recordWebcam.status !== "RECORDING" || isPlaying) &&
              "bg-gray-300 text-gray-500 font-light cursor-not-allowed"
            }`}
            disabled={recordWebcam.status !== "RECORDING" || isPlaying}
            onClick={handleRecordingComplete}
          >
            Submit Answer
          </button>
        </div>
      </div> */}
      <div id="record_wrapper" className={css.camera_dimentions}>
        <video
          ref={recordWebcam.webcamRef}
          style={{
            display:
              recordWebcam.status === "OPEN" ||
              recordWebcam.status === "RECORDING"
                ? "block"
                : "none",
          }}
          autoPlay
          // muted={isPlaying}
          muted
        />
        <video
          ref={recordWebcam.previewRef}
          style={{
            display: recordWebcam.status === "PREVIEW" ? "block" : "none",
          }}
          autoPlay
          muted
          loop
        />
        <div className={css.video_actions_div}>
          <Tooltip title="Previous Question" placement="left" arrow>
            <IconButton
              disabled={recordWebcam.status === "RECORDING"}
              // Do not apply the class(red_color) to this icon if recording is going on
              className={recordWebcam.status !== "RECORDING" ? css.icon_color : css.disabled_icon_color}
            >
              <ArrowBackIos />
            </IconButton>
          </Tooltip>
          <Tooltip title="Play Question" placement="top" arrow>
            <IconButton
              className={recordWebcam.status !== "RECORDING" ? css.icon_color : css.disabled_icon_color}
              disabled={isPlaying || recordWebcam.status === "RECORDING"}
              onClick={playAudioQ}
            >
              <PlayCircleOutline fontSize="medium" />
            </IconButton>
          </Tooltip>
          {recordWebcam.status === "RECORDING" ? (
            <Tooltip title="Finish & Submit Answer" placement="top" arrow>
              <IconButton
                className={css.icon_color}
                onClick={handleRecordingComplete}
              >
                <TaskAlt />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Record Answer" placement="top" arrow>
              <IconButton className={css.icon_color} onClick={startRecording}>
                <RadioButtonChecked />
              </IconButton>
            </Tooltip>
          )}
          {showRetake && (
            <Tooltip title="Retake Recording" placement="top" arrow>
              <IconButton className={css.icon_color} onClick={startRecording}>
                <Replay />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Next Question" placement="right" arrow>
            <IconButton
              disabled={recordWebcam.status === "RECORDING"}
              className={recordWebcam.status !== "RECORDING" ? css.icon_color : css.disabled_icon_color}
            >
              <ArrowForwardIos />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default VideoRecord;
