import React from "react";

const LineBar = (props) => {
  const {percentage, title, left, right} = props
  const getHighlightedLines = () => {
    const numOfLines = 5;
    const highlightedLines = Math.ceil((percentage / 100) * numOfLines);
    return Array.from({ length: numOfLines }, (_, index) =>
      index < highlightedLines ? "bg-yellow-200" : "bg-gray-500"
    );
  };

  return (
    <div className=" py-2">
      <h4 className=" text-lg font-bold text-white">{title}</h4>
      <div className="flex justify-center py-2 space-x-1">
        {getHighlightedLines().map((lineClass, index) => (
          <div key={index} className={`h-2 w-14 rounded ${lineClass}`}></div>
        ))}
      </div>
      <div className=" flex px-2 justify-between">
        <p className={`${percentage < 60 ? ' text-white' : ' text-gray-300'  }`}>{left}</p>
        <p className={`${percentage > 60 ? ' text-white' : ' text-gray-300'  }`}>{right}</p>
      </div>
    </div>
  );
};

export default LineBar;
