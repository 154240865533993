import React from "react";
import LineBar from "../../LineBar";
import { Link } from "react-router-dom";

import css from "../../dashboard.module.css";

const Box4 = ({ audio_score, dialog }) => {
  return (
    <div className={css.box3}>
      <div className=" px-4">
        <h2 className=" font-bold text-2xl text-white">Audio Analysis Score</h2>

        <div className=" flex justify-between items-center my-2">
          <h2 className="font-bold text-4xl text-white">{audio_score}%</h2>
          {!dialog && (
            <button className="text-gray-800 font-bold bg-yellow-200 hover:bg-yellow-300 font-md py-2 px-4 rounded-lg">
              <Link to="/dashboard/audio_analysis">View</Link>
            </button>
          )}
        </div>

        <LineBar
          percentage={78}
          title="Choice of Words"
          left="Too Tight"
          right="too Loose"
        />
        <LineBar
          percentage={49}
          title="Tone of Voice"
          left="Uncomforable"
          right="Comfortable"
        />
        <LineBar
          percentage={69}
          title="Level of Confidence"
          left="Poor"
          right="Perfect"
        />
      </div>
    </div>
  );
};

export default Box4;
