import React, { useState } from "react";
import dashboardIcons from "../../resources";
import css from "./section2.module.css";
import TemplateCard from "./TemplateCard";

const Section2 = () => {
  const [activeTab, setActiveTab] = useState("Video");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const data = [
    {
      id: "Video",
      title: `Unleash Nonverbal Excellence with Video Analysis`,
      desc: ` Pluoo AI introduces Video Analysis—a game-changer
         in interview preparation. Our cutting-edge technology
          evaluates your body language, facial expressions,
           and overall presentation, providing personalized feedback 
           to refine your nonverbal communication. Master nonverbal cues, 
           exude confidence, and leave a lasting impression. Elevate
            your interview skills with Pluoo AI today.`,
      image: dashboardIcons.landing.landing_video,
    },
    {
      id: "Audio",
      title: `
        Audio Perfection through Advanced Analysis
        `,
      desc: `
        Experience the future of interview training with Pluoo AI's Audio Analysis. Our state-of-the-art technology dissects your tone, pitch, pacing, and speech patterns, offering personalized insights to perfect your vocal delivery. Master the art of impactful communication, project confidence, and ensure your message resonates. Elevate your interview prowess with Pluoo AI today.
        `,
      image: dashboardIcons.landing.landing_audio,
    },
    {
      id: "Question Generator",
      title: `
        Customized Interview Preparation with Question Generator`,
      desc: `
        Introducing the Question Generator by Pluoo AI—an innovation that adapts to your unique career aspirations. Upload your own job description, and witness our cutting-edge technology craft tailored interview questions. Guided by your target role, we ensure you're primed to excel. Elevate your interview readiness, decode the path to success, and seize your dream job with Pluoo AI today.
        `,
      image: dashboardIcons.landing.landing_video,
    },
  ];
  return (
    <div className={css.section2}>
      <div className={css.inner}>
        <h3 className={css.heading1}>Supercharge Your Interview Skills</h3>
        <h3 className={css.button1}>Unlock Opportunities</h3>
      </div>

      <div className={css.inner2}>
        <div className={css.links}>
          {data.map((tab) => (
            <span
              key={tab.id}
              className={activeTab === tab.id ? css.active : css.not_active}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.id}
            </span>
          ))}
        </div>
        {data.map(
          (tab) =>
            activeTab === tab.id && (
              <div key={tab.id}>
                <TemplateCard
                  id = {tab.id}
                  title={tab.title}
                  description={tab.desc}
                  image={tab.image}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Section2;
