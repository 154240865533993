import React from "react";
import Header from "./../../components/header/Header";

import css from "./templates.module.css";
import TemplatesCard from "./TemplatesCard";
import Result from "../../components/video_result/Result";

const Templates = () => {
  return (
    <div>
      <Header title="Templates Made For You" size={1.75} />
      <div className="p-4 md:0">
        {/* only show this title in small screen, hidden from md: and lg: screen */}
        <h2 className={css.mobile_title}>Templates Made for You</h2>
        <div className={css.category_links_wrapper}>
          <TemplatesCard />
        </div>
      </div>
      <Result />
    </div>
  );
};

export default Templates;
