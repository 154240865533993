import React, { useEffect } from "react";
import dashboardIcons from "./../../resources/index";
// import Header from "./../../components/header/Header";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

import css from "./support.module.css";
import Result from "../../components/video_result/Result";
const Support = () => {
  const navigateForm = useNavigate(); // Using this to navigate, when Form is submitted

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tml8p8z",
        "template_2fiskvn",
        e.target,
        "uVsLxHxBHY-6G5nzA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigateForm("./suggestionsubmitted"); //navigate here on form submisstion
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Header title="Contact Support" size={1.75} /> */}
      <div className="p-4 md:px-8">
        <h2 className={css.mobile_title}>Contact Support</h2>
        <div className={css.contact_text_wrapper}>
          <div>
            <h3 className=" text-gray-800 font-medium text-xl">
              Welcome to Pluuo AI
            </h3>
            <p className=" text-gray-400">We are available to help you 24/7</p>
          </div>
          <div className={css.contact_img}>
            <img
              src={dashboardIcons.images.contact_support}
              alt="Contact Support"
            />
          </div>
        </div>
        <div className={css.form_wrapper}>
          <div className="p-6 flex-1">
            <form
              method="POST"
              onSubmit={sendEmail}
              className=" flex gap-8 flex-col justify-start "
            >
              <div className=" flex gap-2 flex-col">
                <p className=" text-gray-500 font-medium text-base">
                  Full Name
                </p>
                <input
                  type="text"
                  placeholder="Username"
                  name="name"
                  required
                  autoComplete="off"
                  className=" p-2 focus:outline-none rounded bg-slate-200 focus:bg-slate-300 text-gray-900 leading-6 tracking-wide"
                />
              </div>

              <div className=" flex gap-2 flex-col">
                <p className=" text-gray-500 font-medium text-base">Email</p>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  autoComplete="off"
                  className=" p-2 focus:outline-none rounded bg-slate-200 focus:bg-slate-300 text-gray-900 leading-6 tracking-wide"
                />
              </div>

              <div className=" flex gap-2 flex-col">
                <p className=" text-gray-500 font-medium text-base">Issue</p>
                <select
                  name="issue"
                  className=" p-2 focus:outline-none rounded bg-slate-200 focus:bg-slate-300 text-gray-900 leading-6 tracking-wide"
                  required
                >
                  <option>Payement Problem</option>
                  <option>Other Issues</option>
                  <option>Billing Problem</option>
                  <option>Something else</option>
                </select>
              </div>

              <div className=" flex gap-2 flex-col">
                <p className=" text-gray-500 font-medium text-base">
                  Description
                </p>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Write Here"
                  required
                  autoComplete="off"
                  cols="30"
                  rows="5"
                  className=" p-2 focus:outline-none rounded bg-slate-200 focus:bg-slate-300 text-gray-900 leading-6 tracking-wide"
                ></textarea>
              </div>

              <input
                style={{
                  backgroundColor: "#333334",
                  color: "#FFF27A",
                  borderRadius: "2rem",
                }}
                className=" cursor-pointer text-white font-bold py-2 px-4 rounded transform hover:scale-95 transition duration-300"
                type="submit"
                value="Send"
              />
            </form>
          </div>

          {/* use this to give the form as 50% width, No more work */}
          <div className=" flex-1"></div>
        </div>
      </div>
      <Result />
    </div>
  );
};

export default Support;
