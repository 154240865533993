import React, { useRef } from "react";
import dashboardIcons from "../../resources";
import css from "./section1.module.css";
import { useNavigate } from "react-router-dom";

import {useAnalysisContext} from "../../context/AnalysisContext"

const Section1 = () => {

  //To store the email here and display the same email in /Signup page
  const {changeTrypluuoEmail} = useAnalysisContext()
  // const brands = [
  //   { image: dashboardIcons.landing.honda },
  //   { image: dashboardIcons.landing.sony },
  //   { image: dashboardIcons.landing.lyft },
  //   { image: dashboardIcons.landing.adidas },
  //   { image: dashboardIcons.landing.nike },
  // ];

  const emailRef = useRef()
  const navigate = useNavigate()
  
  const handleTry = ()=>{
    changeTrypluuoEmail(emailRef.current.value)
    navigate("/signup")
  }

  return (
    <div>
      <div className={css.section1}>
        <div className={css.text}>
          <h2 className={css.heading1}>
            Master the art of 
            Interviewing
          </h2>
          <p className={css.subheading1}>
            Your Personal Interview Coach: Transforming Interviews,<br /> Empowering
            Careers
          </p>
          <form className={css.form}>
            <input
              type="email"
              required
              ref={emailRef}
              placeholder="Enter your email adress"
              className={css.input}
            />
            <button onClick={handleTry} className={css.button1}>
              Try Pluuo Free
            </button>
          </form>
        </div>
        <div className={css.dashboard_img_div}>
          <img src={dashboardIcons.landing.landing_img} alt="Dashboard" />
        </div>
      </div>

      {/* brands */}
      {/* <div className={css.brands_wrapper}>
        <div className={css.brands}>
          {brands.map((barnd, i) => {
            return (
              <img
                key={i}
                src={barnd.image}
                alt="Brand Name"
                className=" cursor-pointer"
              />
            );
          })}
        </div>
        <p className=" text-center text-lg font-normal">
          Gain the competitive edge in your job search with our cutting-edge
          platform designed to enhance your interview skills.
        </p>
      </div> */}
    </div>
  );
};

export default Section1;
