import React from "react";
import { Link } from "react-router-dom";
import dashboardIcons from "../../../resources";

const CurrentPlan = () => {
  return (
    <div
      style={{ backgroundColor: "#F3F3E4" }}
      className="  p-2 rounded-xl shadow-md"
    >
      <h2 className="font-bold text-black text-xl  md:text-2xl text-center">
        Your Current Plan
      </h2>
      <div className=" flex items-center justify-between my-8 p-3 bg-white rounded shadow-md">
        <div className=" flex ">
          <img
            src={dashboardIcons.icons.current_plan_icon}
            alt="Current Plan"
          />
          <span className=" ml-2 font-semibold">Basic</span>
        </div>
        <h5 className=" text-lg font-semibold">$24</h5>
      </div>
      <Link
        to="/dashboard/payment"
        className="text-gray-800 bg-yellow-200 hover:bg-yellow-300 font-lg p-2 rounded-2xl font-bold  flex items-center justify-center shadow-2lg"
      >
        Upgrade
      </Link>
      <Link to="/history" className="flex justify-center my-2">
        <span className="text-gray-800 border-b-2 border-gray-600 text-sm">
          View Payment History
        </span>
      </Link>
    </div>
  );
};

export default CurrentPlan;
