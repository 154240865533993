import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuthContext } from "../../context/UserAuthContext";
const ProtectedRoute = ({ children }) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const { user } = useUserAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("Check user in Private: ", user);
  useEffect(() => {
    // Check if the user is not logged in and should be redirected to login
    if (!user && location.pathname !== "/login" && !redirectToLogin) {
      // Redirect to the login page
      setRedirectToLogin(true);
      navigate("/login");
    }
  }, [user, location.pathname, navigate, redirectToLogin]);
  return children;
};

export default ProtectedRoute;
