import React from "react";
// import TemplatesCard from "../TemplatesCard";

const Marketer = () => {
  const category = "Marketer";
  // const marketerData = [
  //   { title: "Interview Of Junior Marketer Officer" },
  //   { title: "Interview Of Senior Markerter Officer" },
  //   { title: "Interview Of Mid Level Markerter Officer" },
  //   { title: "Interview Of Expert Markerter Officer" },

  // ];
  return (
    // <TemplatesCard category = {category} dataList = {marketerData} />
    <strong>{category}</strong>

  );
};

export default Marketer;
