import logo from "./logo.png";
import book from "./book.png";
import dashboard from "./dashboard.png";
import history from "./history.png";
import template from "./template.png";
import question from "./question.png";
import mic from "./mic.png";
import video from "./video.png";
import img1 from "./img1.png";
import current_plan_icon from "./current_plan_icon.png";
import atm_card from "./atm_card.png";

import average from "./average.png";
import good from "./good.png";
import poor from "./poor.png";
import excellent from "./excellent.png";

import fourty from "./fourty.png";
import fifty from "./fifty.png";
import seventy from "./seventy.png";
import eighty from "./eighty.png";
import ninty from "./ninty.png";

import contact_support from "./contact_support.png";
import profile_cover from "./profile_cover.png";

import dashboard_ss from "./dashboard_ss.png";
import honda from "./honda.png";
import nike from "./nike.png";
import lyft from "./lyft.png";
import adidas from "./adidas.png";
import sony from "./sony.png";
import landing_audio from "./landing_audio.png";
import landing_video from "./landing_video.png";
import landing_img from "./landing_img.png";
import landing_template_1 from "./landing_template_1.png";
import landing_template_2 from "./landing_template_2.png";
import landing_template_3 from "./landing_template_3.png";
import landing_template_4 from "./landing_template_4.png";
import landing_template_5 from "./landing_template_5.png";
import landing_template_6 from "./landing_template_6.png";
import interview_demo from "./interview_demo.png";

import google_icon from "./google_icon.svg";

const dashboardIcons = {
  icons: {
    logo,
    dashboard,
    book,
    history,
    template,
    question,
    mic,
    video,
    img1,
    current_plan_icon,
    atm_card,
    google_icon
  },

  percent: {
    poor,
    average,
    good,
    excellent,
    fourty,
    fifty,
    seventy,
    eighty,
    ninty,
  },

  images: {
    contact_support,
    profile_cover,
  },

  landing: {
    dashboard_ss,
    landing_img,
    honda,
    nike,
    lyft,
    adidas,
    sony,
    landing_audio,
    landing_video,
    landing_template_1,
    landing_template_2,
    landing_template_3,
    landing_template_4,
    landing_template_5,
    landing_template_6,
    interview_demo
  },
};

export default dashboardIcons;
