import React from "react";
import ProgressBar from "react-customizable-progressbar";
import { Link } from "react-router-dom";

import css from '../../dashboard.module.css'

const Box3 = ({ video_score, dialog }) => {

    var video_status = ""
    if (video_score >= 80) {
        video_status = 'Excellent';
      } else if (video_score >= 60) {
        video_status = 'Good';
      } else if (video_score >= 45) {
        video_status = 'Average';
      } else {
        video_status = 'Poor';
      }
  return (
    <div className={css.box3}>
      <h2 className=" font-bold text-2xl text-white">Video Analysis Score</h2>
      <div className="flex items-center justify-center py-4">
        <ProgressBar
          radius={90}
          progress={video_score}
          strokeWidth={16}
          trackStrokeWidth={16}
          strokeColor="#FFF27A"
          trackStrokeColor="#fff"
          trackStrokeLinecap="round"
          strokeLinecap="round"
          pointerRadius={10}
          pointerStrokeWidth={10}
          pointerStrokeColor="#FFF27A"
          pointerFillColor="#fff"
          cut={180}
          rotate={180}
        >
          <div className={css.indicator}>
            <span className=" font-bold text-white text-5xl">
              {video_score}%
            </span>
          </div>
        </ProgressBar>
      </div>
      <div className={` text-center p-2 ${css.box3_desc}`}>
        <p className=" text-yellow-200 text-lg">
          Your video analysis is <strong>{video_status}</strong>
        </p>
        {
            !dialog && (<div>
          <p className="text-white mb-3 text-md">Last check on 24 June 2023</p>
          <button className="text-gray-600 bg-white hover:bg-gray-200 font-md py-2 px-4 rounded-lg">
            <Link to="/dashboard/video_analysis">What these stats means? </Link>
          </button>
        </div>)
        }
      </div>
    </div>
  );
};

export default Box3;
