import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { Notifications, Add } from "@mui/icons-material/";
import {
  Avatar,
  Box,
  Tooltip,
  Menu,
  Typography,
  MenuItem,
} from "@mui/material/";
import { yellow } from "@mui/material/colors";

import { useUserAuthContext } from "../../context/UserAuthContext";
import SetJobTitleCard from "./SetJobTitleCard";

const Header = ({ title, size, border, analysis }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { user, logOut } = useUserAuthContext();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    logOut();
  };

  return (
    // hide haeder for small screens. That's why using tailwind class hidden
    <div className="hidden md:flex md:flex-col md:justify-between md:items-center md:w-full md:p-8 md:bg-gray-100">
      <div className="hidden md:flex md:justify-between md:items-center md:w-full  md:bg-gray-100">
        <div
          style={{
            borderBottom: border ? "2px solid gray" : "none",
            width: border ? "25rem" : "",
          }}
        >
          <h3 style={{ fontSize: `${size}rem` }} className="font-bold">
            {title}
          </h3>
        </div>
        <div className=" flex items-center justify-center gap-4">
          {/* <Badge
            color="secondary"
            overlap="circular"
            badgeContent="4 "
            className=" cursor-pointer bg-white rounded-lg p-2"
          >
            <Notifications />
          </Badge> */}
          <div className="cursor-pointer bg-white rounded-lg">
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="User Profile">
                <div
                  onClick={handleOpenUserMenu}
                  className="gap-2 p-1 flex items-center justify-center"
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      bgcolor: yellow[200],
                      color: `#000`,
                    }}
                    src={user?.photoURL}
                    alt={user?.email}
                  >
                    {/* if user sign in with email, show the first charter of his email in the profile pic */}
                    {user?.photoURL
                      ? null
                      : user?.email.charAt(0).toUpperCase()}
                  </Avatar>
                  <p
                    style={{
                      fontSize: "1rem",
                      maxWidth: "8rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {user?.displayName}
                  </p>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <MenuItem onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{user.displayName}</Typography>
              </MenuItem> */}
                <p className=" text-center px-2">{user?.displayName}</p>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography onClick={() => logoutUser()} textAlign="center">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </div>
        </div>
      </div>

      {/* Do not show this in Analysis Page geeting this props from analysis page */}
      {/* SetJobTitleCard s used to set the custome job title for interview */}
      {!analysis && <SetJobTitleCard />}
    </div>
  );
};

export default Header;
