import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAnalysisContext } from "../../context/AnalysisContext";

export const jobTitles = [
  "Software Engineer",
  "Data Scientist",
  "Investment Banker",
  "Doctor",
];

const SetJobTitleCard = () => {
  const { sendAnalysisTitle, takeInterviewTitle, takeInterviewTitleChange } =
    useAnalysisContext();

  const [jobTitleType, setJobTitleType] = useState("select");
  const [customJobTitle, setCustomJobTitle] = useState("");

  const handleJobTitleChange = (event) => {
    const value = event.target.value;
    if (value === "other") {
      setJobTitleType("other");
      setCustomJobTitle(""); // Clear custom job title input
    } else {
      takeInterviewTitleChange(value);
    }
  };

  const handleCustomJobTitleChange = (event) => {
    setCustomJobTitle(event.target.value);
  };

  const handlesetJobTitle = () => {
    const finalJobTitle =
      jobTitleType === "select" ? takeInterviewTitle : customJobTitle;
    takeInterviewTitleChange(finalJobTitle);
    sendAnalysisTitle({
      interviewTitle: finalJobTitle,
    });
  };

  const handleSwitchToSelect = () => {
    setJobTitleType("select");
    takeInterviewTitleChange(""); // Clear selected job title
  };

  return (
    <form className="md:mt-4 md:my-0 md:mb-0 mb-4 my-2 flex flex-col items-center p-8 bg-gray-200 rounded-lg shadow-md">
      <div className="flex flex-wrap items-center gap-2 font-mono mb-8">
        <span>Hi, I want to become a </span>
        {jobTitleType === "select" ? (
          <select
            value={takeInterviewTitle}
            onChange={handleJobTitleChange}
            className="bg-transparent border-none text-green-600 text-lg focus:outline-none"
          >
            <option value="" disabled>
              Select job title
            </option>
            {jobTitles.map((title, index) => (
              <option key={index} value={title}>
                {title}
              </option>
            ))}
            <option value="other">Other</option>
          </select>
        ) : (
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Enter custom job title"
              value={customJobTitle}
              onChange={handleCustomJobTitleChange}
              className="bg-transparent border-none text-green-600 text-lg focus:outline-none"
              style={{
                width: takeInterviewTitle
                  ? `${takeInterviewTitle.length * 10}px`
                  : "11rem",
              }}
            />
            <button
              type="button"
              onClick={handleSwitchToSelect}
              className="text-red-500 hover:text-red-700"
            >
              X
            </button>
          </div>
        )}
        <span>can you help me prepare?</span>
      </div>
      <Link
        to="/dashboard/takeAnalysis"
        className=" flex items-center justify-center bg-white rounded-lg cursor-pointer py-4 px-12 gap-2 hover:bg-slate-50"
        onClick={handlesetJobTitle}
      >
        {/* <p className="font-semibold text-lg">Practice Now</p> */}
        Practice Now
        <Add />
      </Link>
    </form>
  );
};

export default SetJobTitleCard;
