import React, { useState } from "react";
import VideoCard from "./VideoCard";
import QuestionCard from "./QuestionCard";
// import { ArrowBackIosNew } from "@mui/icons-material/";
import { useAnalysisContext } from "../../../../context/AnalysisContext";
import css from "./takeAnalysis.module.css";
// import Result from "./Result";
import Result from "../../../../components/video_result/Result";

// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";
import DialogCard from "./DialogCard";

const TakeAnalysis = () => {
  const { jobTitle } = useAnalysisContext();

  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [videoSize, setVideoSize] = useState(null);
  const [startRecord, setStartRecord] = useState(false);
  const navigate = useNavigate();
  //As we have to set Loader here, That's why using Loading here
  //and passing function as props to update isLoading vaue
  const displayResult = () => {
    setLoading(true);
    setShowResult(true);

    // Automatically hide the Result after 5 seconds
    setTimeout(() => {
      setShowResult(false);
      setLoading(false);
      navigate("/dashboard");
    }, 2000); // 5000 milliseconds = 5 seconds
  };

  const hideResult = () => {
    setShowResult(false);
    navigate("/dashboard");
  };

  const loadingTrue = () => {
    setLoading(true);
  };

  const loadingFalse = () => {
    setLoading(false);
  };

  //We are calling this when prep time reaches to zero in /Questioncard to say now start recording
  const handleStartRecord = () => {
    setStartRecord(true);
  };

  const handleSetVideoSize = (size) => {
    let tempSize = size / 1024;
    setVideoSize(tempSize.toFixed(2));
  };

  // console.log("Video Size:", videoSize, "kb");
  return (
    <div className={css.take_analysis}>
      {/* <Tooltip className={css.back_icon_wrapper} title="Go Back" placement="right" arrow>
        <IconButton onClick={()=>{navigate("/dashboard")}} >
          <ArrowBackIosNew className={css.back_icon} />
        </IconButton>
      </Tooltip> */}

      <h3 className={css.job_title}>
        {jobTitle ? jobTitle : "Frontend Devloper"}
      </h3>
      <div className={css.mobile_screen}>
        <VideoCard
          loadingTrue={loadingTrue}
          loadingFalse={loadingFalse}
          displayResult={displayResult}
          handleSetVideoSize={handleSetVideoSize}
          startRecord={startRecord}
        />
        <QuestionCard handleStartRecord={handleStartRecord} />
      </div>
      {showResult && (
        <DialogCard
          videoSize={videoSize}
          hideResult={hideResult}
          loading={loading}
        />
      )}
      <Result />
    </div>
  );
};

export default TakeAnalysis;
