import React from 'react'
import Header from './../components/header/Header';

const Section1 = () => {
  return (
    <div>
        <Header />
    </div>
  )
}

export default Section1