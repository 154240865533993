import React from 'react'
// import LandingAnnouncement from './LandingAnnouncement'
import LandingHeader from './LandingHeader'
import Section1 from './section1/Section1'
import Section2 from './section2/Section2'
import Section3 from './section3/Section3'
import LandingFooter from './LandingFooter'
import Typewriter from './typeWriter/TypeWriter'

const LandingPage = () => {
  return (
    <div className=''>
        {/* <LandingAnnouncement /> */}
        <LandingHeader />
        <Section1 />
        <Typewriter />
        <Section2 />
        <Section3 />
        {/* <div className={css.dashboard_ss_div}>
          <img src={dashboardIcons.landing.dashboard_ss} alt="Dashboard" />
        </div>
        <hr /> */}
        <LandingFooter />
    </div>
  )
}

export default LandingPage