import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Templates from "./pages/templates/Templates";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/login/ForgotPassword";
import SignUp from "./pages/signup/SignUp";
import ErrorPage from "./pages/error/ErrorPage";

import VerifyEmail from "./components/auth_helper/VerifyEmail";
import ProtectedRoute from "./components/auth_helper/ProtectedRoute";

import { UserAuthContextProvider } from "./context/UserAuthContext";
import { AnalysisContextProvider } from "./context/AnalysisContext";

import "./app.css";
import History from "./pages/history/History";
import Resources from "./pages/resource/Resources";
import Sidebar from "./components/sidebar/Sidebar";
import Payment from "./pages/dashboard/payment/Payment";
import Analysis from "./pages/dashboard/analysis/Analysis";
import AudioAnalysis from "./pages/dashboard/audio_analysis/AudioAnalysis";
import VideoAnalysis from "./pages/dashboard/video_analysis/VideoAnalysis";

import Support from "./pages/support/Support";
import LandingPage from "./landing/LandingPage";
import Profile from "./profile/Profile";
import TakeAnalysis from "./pages/dashboard/analysis/takeanalysis/TakeAnalysis";
import { getPreviousScores } from "./components/firebase_utils/firebase";
import { HistoryDataContextProvider } from "./context/HistoryDataContext";

const App = () => {
  const location = useLocation();

  //Use to sidebar collapse and expand
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  //These are the paths where we want Sidebar to show, else it will not show
  const sidebarPaths = [
    "/dashboard",
    "/dashboard/",
    "/templates",
    "/templates/",
    "/history",
    "/history/",
    "/resources",
    "/resources/",
    // "/support",
    // "/support/",
    "/dashboard/payment",
    "/dashboard/payment/",
    "/dashboard/analysis",
    "/dashboard/analysis/",
    "/dashboard/audio_analysis",
    "/dashboard/audio_analysis/",
    "/dashboard/video_analysis",
    "/dashboard/video_analysis/",
    "/verification",
    "/verification/",
    "/profile",
    "/profile/",
  ];

  return (
    <>
      <UserAuthContextProvider>
        <AnalysisContextProvider>
          <HistoryDataContextProvider>
            <div className="app_wrapper">
              {/* Include Sidebar if pathname is present in the "sidebarPaths" list */}
              {sidebarPaths.includes(location.pathname) && (
                <div
                  className={`app_sidebar  ${
                    isSidebarOpen ? "" : "collapsed_sidebar"
                  }`}
                >
                  <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                  />
                </div>
              )}
              <div className="app_body">
                <Routes>
                  <Route exact path="/">
                    <Route exact path="/" element={<LandingPage />} />
                    <Route path="/*" element={<ErrorPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/resetPassword" element={<ForgotPassword />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/support" element={<Support />} />

                    {/* Protexted Routes */}
                    <Route
                      exact
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/templates"
                      element={
                        <ProtectedRoute>
                          <Templates />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/history"
                      element={
                        <ProtectedRoute>
                          <History />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/resources"
                      element={
                        <ProtectedRoute>
                          <Resources />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route
                    exact
                    path="/support"
                    element={
                      <ProtectedRoute>
                        <Support />
                      </ProtectedRoute>
                    }
                  /> */}
                    <Route
                      exact
                      path="/dashboard/payment"
                      element={
                        <ProtectedRoute>
                          <Payment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/analysis"
                      element={
                        <ProtectedRoute>
                          <Analysis />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/takeAnalysis"
                      element={
                        <ProtectedRoute>
                          <TakeAnalysis />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/audio_analysis"
                      element={
                        <ProtectedRoute>
                          <AudioAnalysis />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/video_analysis"
                      element={
                        <ProtectedRoute>
                          <VideoAnalysis />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/verification"
                      element={
                        <ProtectedRoute>
                          <VerifyEmail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </HistoryDataContextProvider>
        </AnalysisContextProvider>
      </UserAuthContextProvider>
    </>
  );
};

export default App;
