import React from "react";
// import TemplatesCard from "../TemplatesCard";

const Finance = () => {
  const category = "Finance";
  // const financeData = [
  //   { title: "Interview Of Junior Accounting Officer" },
  //   { title: "Interview Of Senior Accounting Officer" },
  //   { title: "Interview Of Mid Level Accounting Officer" },
  //   { title: "Interview Of Junior Accounting Officer3" },
  //   { title: "Interview Of Junior Accounting Officer5" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {financeData} />
    <strong>{category}</strong>

  );
};

export default Finance;
