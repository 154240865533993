import React from "react";
// import TemplatesCard from "../TemplatesCard";

const ProjectManager = () => {
  const category = "ProjectManager";
  // const projectManagerData = [
  //   { title: "Interview Of Junior Project ManagerData Officer" },
  //   { title: "Interview Of Senior Project ManagerData Officer" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {projectManagerData} />
    <strong>{category}</strong>

  );
};

export default ProjectManager;
