import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useAnalysisContext } from "../../context/AnalysisContext";

// import css from "./takeAnalysis.module.css";
import css from "../../pages/dashboard/analysis/takeanalysis/takeAnalysis.module.css";
// import AspectScore from "../aspects/AspectScore";
import AspectScore from "../../pages/dashboard/analysis/aspects/AspectScore";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        className=" flex justify-between items-center text-gray-600"
        // className={css.dialog_nav}
      >
        <h3 className=" font-semibold text-xl">{children}</h3>
        {onClose ? (
          <div
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <button className=" bg-gray-800 hover:bg-gray-950 text-gray-100 text-lg px-4 rounded-full ml-4">
              Close
            </button>
          </div>
        ) : null}
      </div>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Result = () => {
  const { analysisScores, showScores, handleShowScores } = useAnalysisContext();

  const handleClose = () => {
    handleShowScores();
  };

  const mapExpressionToScore = (expression) => {
    if (expression === "happy") {
      return 90;
    } else if (expression === "sad") {
      return 50;
    } else if (expression === "angry") {
      return 40;
    } else if (expression === "normal" || expression === "neutral") {
      return 70;
    } else if (expression === "dark") {
      return 40;
    }

    // If the expression is not recognized, return 30 or any default score
    return 30;
  };

  //As we also getting audio_score, extracting that from scores
  const mapAnalysisScoresToArray = (scores) => {
    const scoreEntries = Object.entries(scores);
    return scoreEntries.slice(1).map(([title, score]) => ({
      title,
      score: !isNaN(parseFloat(score))
        ? score * 100
        : mapExpressionToScore(score),
    }));
  };

  var aspectData;
  if (analysisScores) {
    aspectData = mapAnalysisScoresToArray(analysisScores);
  } else {
    //If No analysis data, assign random data
    aspectData = [
      { score: 95, title: "Hand Posture" },
      { score: 41, title: "Eye Control" },
      { score: 59, title: "Body Control" },
      { score: 75, title: "Lighting" },
      { score: 39, title: "Volume" },
      { score: 67, title: "Pitch" },
      { score: 83, title: "Words" },
      { score: 58, title: "Fluency" },
    ];
  }

  return (
    <div>
      {showScores && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={true}
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Score View
          </BootstrapDialogTitle>
          <div className={css.result_wrapper}>
            <div className={css.scores}>
              {aspectData.map((data) => {
                return (
                  <AspectScore
                    key={data.title}
                    title={data.title}
                    score={data.score}
                  />
                );
              })}
            </div>
          </div>
        </BootstrapDialog>
      )}
    </div>
  );
};

export default Result;
