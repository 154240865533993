import { Link, useLocation } from "react-router-dom";
import dashboardIcons from "../../resources/index";
import { useEffect, useState } from "react";
import { Close, CloseFullscreen, OpenInFull } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import { Add } from "@mui/icons-material/";
import {
  Avatar,
  Box,
  Menu,
  Tooltip,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material/";
import { yellow } from "@mui/material/colors";

import { useUserAuthContext } from "../../context/UserAuthContext";
import { useAnalysisContext } from "../../context/AnalysisContext";

import css from "./sidebar.module.css";

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  var location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const { user, logOut } = useUserAuthContext();
  const { sendAnalysisTitle } = useAnalysisContext();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    logOut();
  };

  const takeAnalysisClick = () => {
    sendAnalysisTitle({
      interviewTitle: "Front-end web Development",
    });
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  // Array of page objects
  const pages = [
    {
      path: "/dashboard",
      icon: dashboardIcons.icons.dashboard,
      label: "Dashboard",
    },
    {
      path: "/templates",
      icon: dashboardIcons.icons.template,
      label: "Templates",
    },
    { path: "/history", icon: dashboardIcons.icons.history, label: "History" },
    { path: "/resources", icon: dashboardIcons.icons.book, label: "Resources" },
  ];

  useEffect(() => {
    // console.log("UseEffect in Sidebar!")
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div>
      {/* Only for small screens */}
      <div className={css.mobile_sidebar}>
        {/* Header for only small screen */}
        <div className={css.mobile_icons}>
          {isMobileMenuOpen ? (
            <div className={css.icon_div}>
              <div className={css.close_logo}>
                <Link
                  onClick={closeMobileMenu}
                  to="/dashboard"
                  className="flex items-center"
                >
                  <img
                    src={dashboardIcons.icons.logo}
                    className=" w-8 h-8 mr-2 cursor-pointer"
                    alt="LOGO"
                  />
                  <span className="text-xl font-semibold">Pluuo AI</span>
                </Link>
              </div>
              <Close onClick={toggleMobileMenu} className={css.icon} />
            </div>
          ) : (
            <div className={css.icon_div}>
              <MenuIcon onClick={toggleMobileMenu} className={css.icon} />
              <div className={css.menu_logo}>
                <Link to="/dashboard" className="flex items-center">
                  <img
                    src={dashboardIcons.icons.logo}
                    className=" w-8 h-8 mr-2 cursor-pointer"
                    alt="LOGO"
                  />
                  <span className="text-xl font-semibold">Pluuo AI</span>
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* User Profile and Lists of links for only small screen */}
        <div>
          {isMobileMenuOpen && (
            <div className={css.mobile_links}>
              {/* UserProfile and Practice Now button */}
              <div className=" flex items-center justify-between px-2 mt-4">
                <div className="cursor-pointer bg-white rounded-lg">
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="User Profile">
                      <div
                        onClick={handleOpenUserMenu}
                        className="gap-2 p-1 flex items-center justify-center bg-gray-100 rounded-lg"
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            bgcolor: yellow[200],
                            color: `#000`,
                          }}
                          src={user?.photoURL}
                          alt={user?.email}
                        >
                          {/* if user sign in with email, show the first charter of his email in the profile pic */}
                          {user?.photoURL
                            ? null
                            : user?.email.charAt(0).toUpperCase()}
                        </Avatar>
                        <p
                          style={{
                            fontSize: "1rem",
                            maxWidth: "8rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {user?.displayName}
                        </p>
                      </div>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <p className=" text-center px-2">{user?.displayName}</p>
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Typography
                          onClick={() => logoutUser()}
                          textAlign="center"
                        >
                          Logout
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </div>
                <Link
                  to="/dashboard/takeAnalysis"
                  className=" flex items-center justify-center bg-gray-100 rounded-lg cursor-pointer p-2 gap-2"
                  onClick={takeAnalysisClick}
                >
                  <p>Practice Now</p>
                  <Add />
                </Link>
              </div>
              {/* Links for small screens */}
              <ul className={css.links}>
                {pages.map((page) => (
                  <li key={page.path}>
                    <Link
                      to={page.path}
                      onClick={closeMobileMenu}
                      className={`flex gap-2 items-center font-medium ${
                        location.pathname === page.path ||
                        (location.pathname.startsWith(page.path) &&
                          page.path !== "/")
                          ? "bg-yellow-200 p-2 w-1/2  rounded shadow-md"
                          : "hover:bg-yellow-100 p-2 w-1/2 rounded"
                      }`}
                    >
                      <img
                        src={page.icon}
                        alt={page.label}
                        className={css.linkIcon}
                      />
                      <span>{page.label}</span>
                    </Link>
                  </li>
                ))}
                <br />
                <li>
                  <Link
                    to="/support"
                    className={css.support_link}
                    onClick={closeMobileMenu}
                  >
                    <img src={dashboardIcons.icons.question} alt="Support" />
                    <span>Support</span>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Only for Large screens */}
      {/* If the isSidebarOpen is true,remain as it is: otherwise make its w-16 */}
      <div
        className={` flex flex-col justify-between items-start h-screen fixed left-0  ${
          css.mobile_hide
        } ${isSidebarOpen ? css.sidebar : css.toggle_sidebar} `}
      >
        <div
          className={` relative  p-4 ml-4 ${css.mobile_hide} ${
            isSidebarOpen ? "" : css.collapsed_sidebar
          } `}
        >
          <div
            onClick={() => toggleSidebar()}
            className={`${css.toggle_sidebar_icon} ${css.mobile_hide} ${
              isSidebarOpen ? "" : ""
            }`}
          >
            {isSidebarOpen ? (
              <Tooltip title="Collapse Sidebar" placement="bottom" arrow>
                <IconButton className={css.icon_color}>
                  <CloseFullscreen fontSize="medium" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Expand Sidebar" placement="right" arrow>
                <IconButton className={css.icon_color}>
                  <OpenInFull fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Link
            to="/dashboard"
            className={`flex items-center ${isSidebarOpen ? "" : "hidden"}`}
          >
            <img
              src={dashboardIcons.icons.logo}
              className={`w-12 h-12 mr-4 cursor-pointer`}
              alt="LOGO"
            />
            {isSidebarOpen && (
              <span className="text-2xl font-bold">Pluuo AI</span>
            )}
          </Link>
        </div>

        <nav
          className={`flex-grow ml-4 mt-12 p-4 ${css.mobile_hide} ${
            isSidebarOpen ? "" : css.collapsed_nav
          } `}
        >
          <ul className="space-y-1">
            {pages.map((page) => (
              <li key={page.path} className="sidebar-link">
                <Link
                  to={page.path}
                  className={`flex items-center px-6 py-4 bg-white text-gray-700 font-medium ${
                    //To active the sidebar line on when we are page path even on the subpages
                    //like page/pages (active will be page)
                    location.pathname === page.path ||
                    (location.pathname.startsWith(page.path) &&
                      page.path !== "/")
                      ? "bg-yellow-200 rounded-full shadow-md"
                      : "hover:bg-yellow-100 rounded-full"
                  } ${isSidebarOpen ? "" : css.collapsed_links}`}
                >
                  <img
                    src={page.icon}
                    alt={page.label}
                    className="mr-2 w-5 h-5"
                  />
                  {isSidebarOpen && page.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <Link
          to="/support"
          className={`p-4 ${css.mobile_hide} ${isSidebarOpen ? "" : "p-0"}`}
        >
          <div className="flex items-center cursor-pointer">
            <img
              src={dashboardIcons.icons.question}
              alt="Support"
              className=" mr-2"
            />
            {isSidebarOpen && "Support"}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
