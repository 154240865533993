import React, { useEffect } from "react";
import Header from "./../../components/header/Header";
import Record from "./Record";

import css from "./history.module.css";
import Result from "../../components/video_result/Result";
import { useHistoryContext } from "../../context/HistoryDataContext";

const History = () => {
  const { prevScores } = useHistoryContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-4 md:p-0">
      <Header title="History" size={1.75} />
      <div className=" min-h-screen p-0 md:p-4">
        <h2 className={css.mobile_title}>History</h2>
        {prevScores.length === 0 ? (
          <h2 className="flex justify-center items-center text-xl font-semibold text-gray-700">
            No History to Show
          </h2>
        ) : (
          <Record />
        )}
      </div>
      <Result />
    </div>
  );
};

export default History;
