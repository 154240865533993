import React from "react";
// import TemplatesCard from "../TemplatesCard";

const Graphics = () => {
  const category = "Graphics";
  // const graphicsData = [
  //   { title: "Interview Of Junior Graphics Officer" },
  //   { title: "Interview Of Senior Graphics Officer" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {graphicsData} />
    <strong>{category}</strong>

  );
};

export default Graphics;
