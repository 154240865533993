import React from "react";
import Header from "../../../components/header/Header";
import VideoScore from "./VideoScore";
import ResourceList from "../rightbar/ResourceList";

import css  from "../dashboard.module.css";

const VideoAudioAnalysis = ({analysisData, title}) => {
  return (
    <div>
      <div>
        <Header title={title} size={1.75} />
        <div className=" p-4 md:p-2">
          <h2 className={css.mobile_title}>{title}</h2>
          <div className=" flex flex-wrap justify-center items-center gap-6 text-center">
            {analysisData.map((data) => {
              return <VideoScore key={data.title} title={data.title} score={data.score} />;
            })}
          </div>
          <h4 className="my-4 text-lg font-semibold text-gray-800">
            How You Can Improve?
          </h4>
          {/* now showing the resources with some stying changes in 'dashboard/video_analysis'
          That's passing video_audio prop for differtiation */}
          <ResourceList video_audio = {true} />
        </div>
      </div>
    </div>
  );
};

export default VideoAudioAnalysis;
