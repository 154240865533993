import React from "react";
// import TemplatesCard from "../TemplatesCard";

const UI = () => {
  const category = "UI";
  // const uiData = [
  //   { title: "Interview Of Junior UI/UX Designer" },
  //   { title: "Interview Of Senior UI/UX Designer" },
  //   { title: "Interview Of Mid Level UI/UX Designer" },
  //   { title: "Interview Of Expert UI/UX Designer" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {uiData} />
    <strong>{category}</strong>
  );
};

export default UI;
