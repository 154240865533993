import React, { useRef } from "react";
import css from './section3.module.css' ;
import {useAnalysisContext} from "../../context/AnalysisContext"
import { useNavigate } from "react-router-dom";

const Section3 = () => {

  const {changeTrypluuoEmail} = useAnalysisContext()

  const emailRef = useRef()
  const navigate = useNavigate()
  
  const handleTry = ()=>{
    changeTrypluuoEmail(emailRef.current.value)
    navigate("/signup")
  }


  return (
    <div
      className={css.text}
    >
      <h2 className={css.heading1}>
      Master the art of 
            Interviewing
      </h2>
      <p className={css.subheading1}>
        Uncover your strengths and refine your weaknesses in real-time. Our
        AI-powered<br/> feedback  offers personalized suggestions to help you
        articulate your thoughts <br /> confidently and persuasively.
      </p>
      <form className={css.form}>
        <input
          type="email"
          required
          placeholder="Enter your email adress"
          className={css.input}
          ref={emailRef}
        />
        <button onClick={handleTry} className={css.button1}>
          Try Pluuo Free
        </button>
      </form>
      <p className={css.light_text} >
        Try Pluuo AI for free. No Credit Card Required.
      </p>
    </div>
  );
};

export default Section3;
