import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import PaymentCard from "./PaymentCard";
import dashboardIcons from "../../../resources";
import CurrentPlan from "../rightbar/CurrentPlan";
import ResourceList from "../rightbar/ResourceList";

import css from '../dashboard.module.css'

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-4 md:p-0">
      <Header title="Payment" size={1.5} border={true} />
      <div className={css.payment_wrapper}>
       <h2 className={css.mobile_title}>Payment</h2>
        <div className={css.payment_card}>
          <PaymentCard />
          <div className=" mt-4 p-8 bg-white rounded-lg flex flex-col gap-4 items-center justify-center">
            <h2 className=" text-2xl font-semibold md:font-bold md:text-3xl">Current Card</h2>
            <img src={dashboardIcons.icons.atm_card} alt="ATM" />
            <div>
              <button className="p-2 text-gray-700 font-medium bg-yellow-200 hover:bg-yellow-300 rounded-2xl text-sm">
                Change
              </button>
              <button className="p-2 ml-2 text-gray-700 font-medium bg-red-400 hover:bg-red-500 rounded-2xl text-sm">
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className = {css.payment_right_bar}>
          <div className="flex flex-col ">
            <CurrentPlan  />
            <div className=" my-4"></div>
            <ResourceList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
