import React from "react";
import ProgressBar from "react-customizable-progressbar";
import dashboardIcons from "../../../../resources";
import Analysis from "../Analysis";

import css from '../../dashboard.module.css'

const Box1 = ({ total_score, audio_score, video_score, dialog }) => {
  return (
    <div className={css.box1}>
      <h2 className=" font-bold text-2xl">Total Score</h2>
      <div className="flex items-center justify-center my-2">
        <ProgressBar
          radius={60}
          progress={total_score}
          strokeWidth={12}
          trackStrokeWidth={12}
          strokeColor="#333334"
          trackStrokeColor="#fff"
          trackStrokeLinecap="round"
          strokeLinecap="round"
        >
          <div className={css.indicator}>
            <span className=" font-bold text-4xl">{total_score}%</span>
          </div>
        </ProgressBar>
      </div>

      {!dialog && (
        <div>
          <Analysis
            image={dashboardIcons.icons.video}
            text="Video Analysis"
            score={video_score}
          />
          <hr className="my-4 border-gray-400 opacity-50" />
          <Analysis
            image={dashboardIcons.icons.mic}
            text="Audio Analysis"
            score={audio_score}
          />
        </div>
      )}
    </div>
  );
};

export default Box1;
