import React from "react";
// import TemplatesCard from "../TemplatesCard";

const Frontend = () => {
  const category = "Frontend";
  // const frontendData = [
  //   { title: "Interview Of Junior Frontend Developer" },
  //   { title: "Interview Of Senior Frontend Developer" },
  //   { title: "Interview Of Mid Level Frontend Developer" },
  //   { title: "Interview Of Expert Frontend Developer" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {frontendData} />
    <strong>{category}</strong>

  );
};

export default Frontend;
