import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Box1 from "./boxes/Box1";
import Box3 from "./boxes/Box3";
import Box4 from "./boxes/Box4";
import { useAnalysisContext } from "../../../context/AnalysisContext";

import css from "../dashboard.module.css"

const MainDashboard = () => {
  const { analysisScores } = useAnalysisContext();
  var total_score = 0;
  var video_score = 0;
  var audio_score = analysisScores ? analysisScores?.audio_analysis : 0;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`${css.main_dashboard} flex flex-row flex-wrap justify-center gap-4 mb-5 `}>
      <Box1
        total_score={total_score}
        video_score={video_score}
        audio_score={audio_score}
      />
      <div className={css.box2}>
        <div className="text-center w-full px-4">
          <h2 className=" text-2xl text-center font-semibold md:text-4xl md:font-bold text-white">Speak Well.</h2>
          <p className="text-base mt-2 text-center md:text-xl md:mt-4 text-white">
            Take Control of the Conversation
          </p>
        </div>
        <div className="absolute text-center bottom-16 left-0 right-0">
          <button className="px-8 py-2 bg-yellow-200 hover:bg-yellow-300 text-gray-900 font-medium rounded-full shadow-md">
            <Link to="/dashboard/audio_analysis">Imporve Now</Link>
          </button>
        </div>
      </div>
      <Box3 video_score={video_score} />
      <Box4 audio_score={audio_score} />
    </div>
  );
};

export default MainDashboard;
