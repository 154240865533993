import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Box1 from "../dashboard/main_dash/boxes/Box1";
import Box3 from "../dashboard/main_dash/boxes/Box3";
import Box4 from "../dashboard/main_dash/boxes/Box4";

import css from "./history.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        className=" flex justify-between items-center text-gray-600"
        // className={css.dialog_nav}
      >
        <h3 className=" font-semibold text-xl">{children}</h3>
        {onClose ? (
          <div
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <button className=" bg-gray-800 hover:bg-gray-950 text-gray-100 text-lg px-4 rounded-full ml-4">
              Close
            </button>
          </div>
        ) : null}
      </div>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ActionBtn = ({ jobTitle, formattedDate, tempScore }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className=" flex gap-2">
        <button
          onClick={handleClickOpen}
          className=" bg-yellow-200 hover:bg-yellow-300 p-2 rounded"
        >
          View
        </button>
        <button className=" bg-blue-400 hover:bg-blue-500 p-2 rounded">
          Retake
        </button>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Score View
        </BootstrapDialogTitle>
        <div>
          <div className={css.dialog_title}>
            <p className=" text-gray-500">
              Job Title
              <span className=" text-gray-900 font-semibold ml-2">{jobTitle}</span>
            </p>
            {/* <p className=" text-gray-500">
              Template
              <span className=" text-gray-900 font-semibold ml-2">
                {template}
              </span>
            </p> */}
            <p className=" text-gray-500">
              Date
              <span className=" text-gray-900 font-semibold ml-2">{formattedDate}</span>
            </p>
          </div>
          <div className={css.diolog_boxes}>
            <Box1 total_score={tempScore} dialog={true} />
            <Box3 video_score={tempScore} dialog={true} />
            <Box4 audio_score={tempScore} dialog={true} />
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default ActionBtn;
