import React from "react";
import dashboardIcons from "./../../../../resources/index";

const AspectScore = ({ title, score }) => {
  var imageSrc = "";

  if (score >= 0 && score <= 40) {
    if (score < 20){
      score = 25
    }
    imageSrc = dashboardIcons.percent.fourty;
  } else if (score > 40 && score <= 50) {
    imageSrc = dashboardIcons.percent.fifty;
  } else if (score > 50 && score <= 60) {
    imageSrc = dashboardIcons.percent.average;
  } else if (score > 60 && score <= 70) {
    imageSrc = dashboardIcons.percent.seventy;
  } else if (score > 70 && score <= 80) {
    imageSrc = dashboardIcons.percent.eighty;
  } else if (score > 80 && score <= 100) {
    imageSrc = dashboardIcons.percent.ninty;
  }

  return (
    <div className="flex justify-center items-center relative h-32">
      <img src={imageSrc} alt="Score" className="w-32 h-32" />
      <div className="absolute text-sm font-bold  text-gray-800">{score}%</div>
      <p className="absolute text-xs font-medium tracking-tighter  text-gray-800 bottom-4">
        {title}
      </p>
    </div>
  );
};

export default AspectScore;
