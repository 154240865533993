import React from "react";
import dashboardIcons from "../resources";
import { Link } from "react-router-dom";
import css from './landing.module.css';

const LandingHeader = () => {
  return (
    <div className={css.header}>
      <a href="/" className={css.header_inner}>
        <img
          src={dashboardIcons.icons.logo}
          alt="Pluuo AI"
        />
        <span className="text-2xl font-bold">Pluuo AI</span>
      </a>

      <nav>
        <ul>
          <li className={css.li}>
            <Link to="/signup" className={css.links}>
              Pricing
            </Link>
            <Link to="/signup" className={css.links}>
              Resources
            </Link>
            <Link to="/login" className={css.button1}>
              Login
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default LandingHeader;
