import React from "react";
import Section1 from "./Section1";
import dashboardIcons from "./../resources/index";

const Profile = () => {
  return (
    <div>
      <Section1 />
      <div>
        <img src={dashboardIcons.images.profile_cover} alt="Profile Cover" />
      </div>
    </div>
  );
};

export default Profile;
