import React, { useEffect, useState } from "react";
import css from "./takeAnalysis.module.css";
import { useRecordWebcam } from "react-record-webcam";

import { useAnalysisContext } from "../../../../context/AnalysisContext";
import { Mic, MicOff, StopCircle } from "@mui/icons-material";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { saveScores } from "../../../../components/firebase_utils/firebase";
import { useUserAuthContext } from "../../../../context/UserAuthContext";

const VideoCard = ({
  loadingTrue,
  loadingFalse,
  displayResult,
  handleSetVideoSize,
  startRecord,
}) => {
  const { setAnalysisScoresHandler, jobTitle } =
    useAnalysisContext(); //storing the analysis scores in the context

  const [micEnabled, setMicEnabled] = useState(true);
  const [startDisabled, setStartDisabled] = useState(false);
  const { user } = useUserAuthContext();
  const [stopDisabled, setStopDisabled] = useState(true); //initially disable the stop recording button

  // console.log("analysisScore: ", analysisScore);
  //Recorded Video dimentions, download type and name
  const recordWebcam = useRecordWebcam({
    mimeType: "video/mp4",
    fileName: "PluuoAI interview",
    width: "1920",
    height: "1080",
    // aspectRatio: "1.6",
  });

  useEffect(() => {
    recordWebcam.open();
  }, []);

  useEffect(() => {
    // As the prep time reaches to 0, start the recording:
    if (recordWebcam.status === "OPEN" && startRecord) {
      recordWebcam.start();
      setStartDisabled(true);
      setStopDisabled(false);
    }
  }, [recordWebcam.status, startRecord]);

  // const startRecording = async () => {
  //   recordWebcam.open();

  //   setStartDisabled(true);
  //   setStopDisabled(false);
  // };

  const handleRecordingComplete = async () => {
    recordWebcam.stop();
    setStopDisabled(true);
    setStartDisabled(false);
    recordWebcam.close();
    // await new Promise((resolve) => setTimeout(resolve, 200));

    loadingTrue();
    displayResult();
    // Add a delay of 500ms (0.2s seconds) to get the complete vidoe
    await new Promise((resolve) => setTimeout(resolve, 200));
    // setShowRetake(true);
    const videoBlob = await recordWebcam.getRecording();
    console.log("Recorded Video Blob:", videoBlob?.size);
    handleSetVideoSize(videoBlob?.size);

    const formData = new FormData();

    formData.append("video", videoBlob, "recorded_video.mp4");
    try {
      const response = await fetch("https://pluuo-backend-v2-237533y6va-uc.a.run.app/check", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse the response JSON

        await saveScores(user, { ...responseData, jobTitle }); // saving score to backedn i.e firestore
        setAnalysisScoresHandler(responseData);
        loadingFalse();
        recordWebcam.close();
      } else {
        console.error("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      loadingFalse();
      recordWebcam.close();
      setStartDisabled(false);
      setStopDisabled(true);
      // setShowRetake(true);
    }
  };

  const toggleMic = () => {
    setMicEnabled((prevMicEnabled) => !prevMicEnabled);
  };

  return (
    <div className={css.video_card}>
      <div>
        {/* <p className="p-2 bg-slate-600 text-red-300">{recordWebcam.status}</p> */}
        {/* <button onClick={recordWebcam.close}>Close</button> */}
        <video
          ref={recordWebcam.webcamRef}
          className={css.video}
          style={{
            // maxWidth: "130%",
            display:
              recordWebcam.status === "OPEN" ||
              recordWebcam.status === "RECORDING"
                ? "block"
                : "none",
          }}
          autoPlay
          muted
          playsInline
        />
        <video
          ref={recordWebcam.previewRef}
          className="video"
          style={{
            // maxWidth: "130%",
            display: recordWebcam.status === "PREVIEW" ? "block" : "none",
          }}
          autoPlay
          muted
          loop
          playsInline
        />
        <div className={css.video_actions_div}>
          {micEnabled ? (
            <Tooltip title="Unmuted" placement="top" arrow>
              <IconButton className={css.icon_wrapper} onClick={toggleMic}>
                <Mic className={css.icon} fontSize="larger" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Muted" placement="top" arrow>
              <IconButton className={css.icon_wrapper} onClick={toggleMic}>
                <MicOff className={css.icon} fontSize="larger" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Finish & Submit Answer" placement="top" arrow>
            <span>
              <IconButton
                className={
                  startDisabled ? css.active_record : css.disabled_icon_wrapper
                }
                onClick={handleRecordingComplete}
                disabled={stopDisabled} // Disable the button at the start or when recording is on..
              >
                <StopCircle
                  className={
                    startDisabled ? css.active_icon : css.disabled_icon
                  }
                  fontSize="larger"
                />
              </IconButton>
            </span>
          </Tooltip>

          {/* <Tooltip title="Start Recording" placement="top" arrow>
            <span>
              <IconButton
                className={
                  startDisabled ? css.disabled_icon_wrapper : css.icon_wrapper
                }
                onClick={startRecording}
                disabled={startDisabled} // Disable the button when recording is started
              >
                <Videocam
                  className={startDisabled ? css.disabled_icon : css.icon}
                  fontSize="larger"
                />
              </IconButton>
            </span>
          </Tooltip> */}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
