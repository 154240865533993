import React from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <div className=" shadow w-80  rounded bg-white p-4 text-center">
        <h4 className="mb-8 text-2xl font-semibold text-center">
          We have sent a Password reset link to your Email Account!
        </h4>
        <div className="flex gap-4 flex-wrap justify-center">
          <a
            className="bg-gray-500 hover:bg-gray-600 px-4 py-2 rounded-lg text-white font-semibold"
            href="https://mail.google.com/mail/u/0/#inbox"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Gmail
          </a>
          <Link
            className=" bg-yellow-200 hover:bg-yellow-300 px-4 py-2 rounded-lg text-green-600 font-semibold"
            to="/login"
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
