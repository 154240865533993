import React from "react";
import dashboardIcons from "../../../resources";
import { Link } from "react-router-dom";

const ResourceList = ({ video_audio }) => {
  // basically video_audio props is used only for VideoAnalysis
  //and AudioAnalysis Components. For a little bit of changing in
  //Resource list that is already present
  const resourceData = [
    {
      id: 1,
      image: dashboardIcons.icons.img1,
      text: "How body gesture creates a difference",
    },
    {
      id: 2,
      image: dashboardIcons.icons.img1,
      text: "How body gesture creates a difference",
    },
    {
      id: 3,
      image: dashboardIcons.icons.img1,
      text: "How body gesture creates a difference",
    },
  ];

  return (
    <div
      className={`flex flex-col rounded bg-white overflow-hidden p-3 ${
        video_audio ? "justify-center items-center mb-4" : ""
      }`}
    >
      {!video_audio && (
        <h2 className="font-bold text-black text-2xl text-center mb-4 md:text-3xl">
          Resources
        </h2>
      )}
      <div className={`${video_audio ? "flex justify-center flex-wrap gap-4" : ""}`}>
        {resourceData.map((resource) => {
          return (
            <div
              key={resource.id}
              className={`flex justify-center items-center gap-2  shadow-lg rounded-lg bg-white ${
                video_audio ? "flex-col p-4 my-2 w-80" : "py-4 px-2 my-2"
              }`}
            >
              <img
                className={`${
                  video_audio
                    ? " h-40 w-full rounded-lg"
                    : "h-12 w-16 rounded-lg"
                } `}
                src={resource.image}
                alt="Resources"
              />
              <p
                className={`overflow-hidden  font-semibold ${
                  video_audio
                    ? " leading-normal self-start text-lg"
                    : "leading-tight font-sm text-xs"
                }`}
              >
                {resource.text}
              </p>
              <button>
                <Link
                  to="/resources"
                  className={`text-gray-800 bg-yellow-200 hover:bg-yellow-300 font-semibold ${
                    video_audio
                      ? " px-32 py-2 rounded-full"
                      : "font-md p-2 font-semibold rounded-lg"
                  } `}
                >
                  Read
                </Link>
              </button>
            </div>
          );
        })}
      </div>
      {!video_audio && (
        <Link
          to="/resources"
          className="text-gray-800 bg-yellow-200 hover:bg-yellow-300 font-md p-1 rounded-2xl font-semibold mt-4 text-center"
        >
          See All
        </Link>
      )}
    </div>
  );
};

export default ResourceList;
