import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

// To save the current score of the user

export const saveScores = async (user, data) => {
  var db = getFirestore();
  const scoresData = {
    ...data,
    date: `${new Date()}`,
    id: `Users/${user?.uid}`,
  };
  await addDoc(collection(db, "Scores"), scoresData);
};

// To get the previous scores of the user

// export const getPreviousScores = async (id) => {
//     var db = getFirestore();
//     const q = query(collection(db, 'Scores'), where("id", "==", `Users/${id}`))
//     onSnapshot(q, snap => {
//         if (!snap.empty) {
//             snap.docs.forEach((doc) => {
//                 console.log(doc.data())
//             })
//         } else {
//             console.log("No documents found with given id")
//         }
//     })
// };

export const getPreviousScores = async (id) => {
  var db = getFirestore();
  const q = query(collection(db, "Scores"), where("id", "==", `Users/${id}`));

  return new Promise((resolve, reject) => {
    const scoresArray = [];

    onSnapshot(
      q,
      (snap) => {
        if (!snap.empty) {
          snap.docs.forEach((doc) => {
            scoresArray.push(doc.data());
          });
          resolve(scoresArray);
        } else {
          // console.log("No documents found with the given id");
          resolve([]); // Return an empty array when no documents are found
        }
      },
      (error) => {
        console.error("Error fetching documents: ", error);
        reject(error);
      }
    );
  });
};
