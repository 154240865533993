import React, { useState } from "react";
import SampleCard from "./categories/SampleCard";
import css from "./templates.module.css";

import { Search, Close } from "@mui/icons-material";

const TemplatesCard = () => {
  //All Data of Templates
  const dataList = [
    {
      category: "Backend Developer",
      titles: [
        "Interview Of Junior Backend Officer",
        "Interview Of Senior Backend Officer",
        "Interview Of Mid Level Backend Officer",
      ],
    },
    {
      category: "Accounting Officer",
      titles: [
        "Interview Of Junior Accounting Officer",
        "Interview Of Senior Accounting Officer",
        "Interview Of Mid Level Accounting Officer",
        "Interview Of Junior Accounting Officer3",
        "Interview Of Junior Accounting Officer5",
      ],
    },
    {
      category: "Frontend Developer",
      titles: [
        "Interview Of Junior Frontend Developer",
        "Interview Of Senior Frontend Developer",
        "Interview Of Mid Level Frontend Developer",
        "Interview Of Expert Frontend Developer",
      ],
    },
    {
      category: "Graphics Officer",
      titles: [
        "Interview Of Senior Graphics Officer",
        "Interview Of Junior Graphics Officer",
      ],
    },
    {
      category: "HR Manager",
      titles: [
        "Interview Of Junior HR Officer",
        "Interview Of Senior HR Officer",
        "Interview Of Expert HR Officer",
      ],
    },
    {
      category: "Marketing Officer",
      titles: [
        "Interview Of Junior Marketer Officer",
        "Interview Of Expert Marketer Officer",
        "Interview Of Senior Marketer Officer",
      ],
    },
    {
      category: "Motion Graphics",
      titles: [
        "Interview Of Senior Motion Graphics Candidates",
        "Interview Of Mid-Level Motion Graphics Candidates",
        "Interview Of Junior Motion Graphics Candidates",
      ],
    },
    {
      category: "Project Manager",
      titles: [
        "Interview Of Senior Project Manager Officer",
        "Interview Of Junior Project Manager Officer",
        "Interview Of Expert Project Manager Officer",
        "Interview Of Mid Level Project Manager Officer",
      ],
    },
    {
      category: "UI Designer",
      titles: [
        "Interview Of Junior UI/UX Designer",
        "Interview Of Senior UI/UX Designer",
        "Interview Of Mid-Level UI/UX Designer",
        "Interview Of Expert UI/UX Designer",
      ],
    },
  ];

  //To show the Category List
  const categories = [
    "Backend",
    "Accounting Officer",
    "Frontend",
    "Graphics",
    "HR",
    "Marketer",
    "Motion",
    "Project Manager",
    "UI",
  ];

  //Initially show all the result with no category
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSearchText(category);
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    setSelectedCategory("");
  };

  //Fiter Data Logice
  const filteredData = dataList.reduce(
    (titles, category) => [
      ...titles,
      ...category.titles
        .filter((title) =>
          title.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((title) => ({
          category: category.category,
          title: title,
        })),
    ],
    []
  );
  const handleClear = () => {
    setSearchText("");
    setSelectedCategory("");
  };
  return (
    <div>
      <div className="category-buttons">
        <div className={css.search_wrapper}>
          <div className={css.search_bar}>
            <input
              placeholder="Search Templates"
              value={searchText}
              onChange={handleSearch}
            />
            <Search className={css.search_icon} />
            {searchText && (
              <Close className={css.clear_search} onClick={handleClear} />
            )}
          </div>
          <ul className=" flex flex-wrap gap-2 my-4">
            {categories.map((category) => {
              return (
                <li
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                  className={`inline-block px-4 py-2 rounded-full cursor-pointer ${
                    selectedCategory === category ? "bg-yellow-200" : "bg-white"
                  }`}
                >
                  {category}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 justify-center items-center">
        {filteredData.map((data, index) => (
          <SampleCard
            key={index}
            category={data.category} // Set the category for each SampleCard
            title={data.title}
          />
        ))}
      </div>
    </div>
  );
};

export default TemplatesCard;
