// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBcM1Bqms29SzH0bd7mjYvBOC_Bc8ZQXJ0",
  authDomain: "pluuo-5e162.firebaseapp.com",
  projectId: "pluuo-5e162",
  storageBucket: "pluuo-5e162.appspot.com",
  messagingSenderId: "996990786534",
  appId: "1:996990786534:web:21f65e777227dba8195427",
  measurementId: "G-ZGKSNXGKSZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export default app