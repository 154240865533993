import React, { useEffect } from "react";
import VideoAudioAnalysis from "./VideoAudioAnalysis";
import { useAnalysisContext } from "../../../context/AnalysisContext";
import Result from "../../../components/video_result/Result";

const VideoAnalysis = () => {
  const { analysisScores } = useAnalysisContext();

  const mapExpressionToScore = (expression) => {
    if (expression === "happy") {
      return 90;
    } else if (expression === "sad") {
      return 50;
    } else if (expression === "angry") {
      return 40;
    } else if (expression === "normal" || expression === "neutral") {
      return 70;
    } else if (expression === "dark") {
      return 40;
    }

    // If the expression is not recognized, return 30 or any default score
    return 30;
  };

  //As we also getting audio_score, extracting that from scores and mapping the score into array
  const mapAnalysisScoresToArray = (scores) => {
    const scoreEntries = Object.entries(scores);
    return scoreEntries.slice(1).map(([title, score]) => ({
      title,
      score: !isNaN(parseFloat(score))
        ? score * 100
        : mapExpressionToScore(score),
    }));
  };

  var aspectData;
  if (analysisScores) {
    aspectData = mapAnalysisScoresToArray(analysisScores);
  } else {
    //If No analysis data, assign random data
    aspectData = [
      { score: 0, title: "Hand Posture" },
      { score: 0, title: "Eye Control" },
      { score: 0, title: "Body Control" },
      { score: 0, title: "Lighting" },
    ];
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VideoAudioAnalysis analysisData={aspectData} title="Video Analysis" />;
      <Result />
    </>
  );
};

export default VideoAnalysis;
