import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuthContext } from "../../context/UserAuthContext";
import logo from "../../resources/logo.png";
import dashboardIcons from "./../../resources/index";
import { useAnalysisContext } from "../../context/AnalysisContext";

const Signup = () => {
  const { signUp, emailVerification, setTimeActive, googleSignin } =
    useUserAuthContext();
    const {tryPluuoEmail} = useAnalysisContext()

  const [email, setEmail] = useState(tryPluuoEmail);
  const [pass, setPass] = useState("");
  const [cPass, setCPass] = useState("");
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false); //for the icon in pass field
  const [showCPassword, setShowCPassword] = useState(false); //for the icon in pass field

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  //To check both pass are same and pass length > 8
  const validatePassword = () => {
    let isValid = true;
    if (pass !== "" && cPass !== "") {
      if (pass !== cPass) {
        isValid = false;
        setError("Passwords does not match");
      } else if (pass.length < 8) {
        isValid = false;
        setError("Passwords should be minimum 8 character long.");
      }
    }
    return isValid;
  };


  const signupSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (validatePassword()) {
      // Create a new user with email and password using firebase
      signUp(email, pass)
        .then(() => {
          emailVerification()
            .then(() => {
              setTimeActive(true);
              navigate("/verification");
            })
            .catch(() => setError("Something unexpected happened! Try again later"));
        })
        .catch(() => setError("Email is already registered!"));
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    try {
      await googleSignin();
      navigate("/dashboard");
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" flex justify-center items-center overflow-hidden bg-gray-100">
      <Box className="p-12">
        <Link to="/">
        <img
          className=" mx-auto w-28 h-28 cursor-pointer"
          src={logo}
          alt="Pluuoai"
        /></Link>
        <h3 className="font-bold text-2xl leading-12 text-center my-5 mb-8">
          Welcome Back to Pluuo AI. <br />
          signUp here
        </h3>
        {error && (
          <p className="px-4 py-2 text-center text-red-500 text-lg font-medium">
            {error}
          </p>
        )}

        <form onSubmit={signupSubmit} className="flex flex-col gap-5 px-4 w-96">
          <TextField
            id="outlined-basic"
            type="email"
            required
            value={email}
            label="Email Address"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              label="Password *"
              required
              onChange={(e) => setPass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password2">
              Confirm Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password2"
              type={showCPassword ? "text" : "password"}
              label="Confirm Password *"
              required
              onChange={(e) => setCPass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCPassword}
                    onMouseDown={handleMouseDownCPassword}
                    edge="end"
                  >
                    {showCPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <button
            className=" text-white font-bold py-2 rounded transform hover:scale-95 transition duration-300"
            style={{
              backgroundColor: "#333334",
              color: "#FFF27A",
              borderRadius: "2rem",
            }}
          >
            SignUp
          </button>
        </form>
        <hr className="border-gray-500 border-opacity-80 w-60 mx-auto my-4 " />

        <div className="font-bold rounded transform hover:scale-95 transition duration-300 mx-4">
          <div onClick={handleGoogleLogin} style={{
              width: "100%",
              backgroundColor: "#333334",
              borderRadius: "2rem",
              color: "#FFF27A",
              padding: "0.5rem 1rem",
            }} className=" flex justify-center gap-4 items-center cursor-pointer">
            <img className=" w-8 h-8 cursor-pointer" src={dashboardIcons.icons.google_icon} alt = "googleIcon" />
            <h4 className=" text-xl font-semibold">SignUp with Google</h4>
          </div>
        </div>
        <div className="text-center mt-2">
          <h6 className=" font-medium text-sm">
            Already have an account? <br />{" "}
            <Link to="/login" className=" text-blue-700 font-medium text-base">
              Login
            </Link>{" "}
            here
          </h6>
        </div>
      </Box>
    </div>
  );
};

export default Signup;
