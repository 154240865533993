import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";

const UserAuthContext = createContext();

export const UserAuthContextProvider = ({ children }) => {
  var db = getFirestore();

  //Initially the user in null. but after first signup, we are storing the user's
  //data in localhost to avoid signup/in on the refresh of page
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user"))); //to check wich user is active

  const [timeActive, setTimeActive] = useState(false); //time after sending confirmation email

  //Signup using emailId and password
  const signUp = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (result) => {

        // Getting the user info and storing isValidTimestamp,
        var user = result.user;
        const userDocRef = doc(db, "Users", user.uid);

        const userDataToStore = {
          id: user.uid,
          email: user.email,
        };

        console.log("Storing this data: ", userDataToStore)
        
        await setDoc(userDocRef, userDataToStore);

      }
    );
  };

  //to send the verification email to user when he enter his signUp
  const emailVerification = () => {
    return sendEmailVerification(auth.currentUser);
  };

  //LogIn using emailId and password
  const logIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  //Logout the user
  const logOut = () => {
    return signOut(auth);
  };

  //to signin with google
  const googleSignin = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .then(async (result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // console.log("Google Autg Provider credential:",credential, "and token is:", token )
        // after signing u storing user info
        var user = result.user;

        const userDocRef = doc(db, "Users", user.uid);

        // Getting the userDocon the bases if ID
        const userDocSnapshot = await getDoc(userDocRef); //  By userDocSnapshot.data() we can access our stored values

        // To prevent overwriting, checking if the user already exists in the database
        if (!userDocSnapshot.exists()) {
          const userDataToStore = {
            id: user.uid,
            email: user.email,
            // name: user.displayName,
          };
          await setDoc(userDocRef, userDataToStore);
        }
        // console.log("user_provider="+user.displayName+" user_email="+user.email+" user_dp="+user.photoURL+" user_verification="+user.emailVerified+" uid="+user.uid);
      })
      .catch((error) => {
        console.log("error=" + error);
      });
  };

  // forgot password, send reset email

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
    // .then(() => {
    //   console.log("Reset email has been Sent!");
    // })
    // .catch((error) => {
    //   // const errorCode = error.code;
    //   const errorMessage = error.message;
    //   console.log("Error Message:", errorMessage)
    // });
  };

  //to notify that a particular user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //when auth change, set user to current user
      setUser(currentUser);
      // console.log("User we are getting is : ", user);
    });
    //we do this becuase when a component is unmounted we don't want this
    //So to clean up we use this method
    return () => {
      unsubscribe();
    };
  });

  // storing the user info in local Storage(to prevent the issue on reload of page)
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);
  return (
    <UserAuthContext.Provider
      value={{
        user,
        signUp,
        logIn,
        emailVerification,
        logOut,
        timeActive,
        setTimeActive,
        googleSignin,
        forgotPassword,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

//custome useContext hook
export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};
