import { createContext, useContext, useEffect, useState } from "react";
import { getPreviousScores } from "../components/firebase_utils/firebase";
import { useAnalysisContext } from "./AnalysisContext";
import { useUserAuthContext } from "./UserAuthContext";

const HistoryDataContext = createContext();

export const HistoryDataContextProvider = ({ children }) => {
  const [prevScores, setPrevScores] = useState([]);
  const { analysisScores } = useAnalysisContext();
  const { user } = useUserAuthContext(); //To get the history of particular user
  var id = user?.uid;
  const getScores = async () => {
    await getPreviousScores(id)
      .then((scores) => {
        setPrevScores(scores);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getScores();
  }, [analysisScores]);

  return (
    <HistoryDataContext.Provider value={{ prevScores }}>
      {children}
    </HistoryDataContext.Provider>
  );
};
// Create a custom hook to access the context
export const useHistoryContext = () => {
  return useContext(HistoryDataContext);
};
