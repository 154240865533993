import React from "react";
import css from './section2.module.css'

const TemplateCard = ({id,image, title, description }) => {
  return (
    <div className={css.video}>
      <div className={css.video_text_wrapper}>
        <h5>{title}</h5>
        <p>
          {description}
        </p>
      </div>
      <img
        // className={css.img1}
        className={id === "Audio" ? css.img2 : css.img1}
        src={image}
        alt="Landing Video"
      />
    </div>
  );
};

export default TemplateCard;
