import React from "react";
// import TemplatesCard from "../TemplatesCard";


const Motion = () => {
  const category = "Motion";
  // const motionData = [
  //   { title: "Interview Of Junior Motion Graphics Candidates" },
  //   { title: "Interview Of Senior Motion Graphics Candidates" },
  //   { title: "Interview Of Mid Level Motion Graphics Candidates" },
  //   { title: "Interview Of Expert Motion Graphics Candidates" },
  // ];
  return (
    // <TemplatesCard category = {category} dataList = {motionData} />
    <strong>{category}</strong>

  );
};

export default Motion;
