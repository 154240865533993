import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const PaymentCard = () => {
  return (
    <div className=" p-8 bg-white rounded-lg">
      <form>
        <label className="text-lg font-semibold mb-2">Card Number</label>
        <input
          type="text"
          pattern="[0-9]*"
          className="border border-gray-300 p-4 mb-4 rounded w-full"
          placeholder="1902 2389 1206 2480"
          required
        />

        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="text-lg font-semibold">
              Expiration Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label={"MM/YY"} views={["month", "year"]} />
            </LocalizationProvider>
          </div>
          <div className="w-1/2 pl-2">
            <label className="text-lg font-semibold">CVV</label>
            <input
              type="text"
              className="border border-gray-300 p-4 w-full rounded"
              placeholder="CVV"
              required
            />
          </div>
        </div>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="border border-gray-300 rounded w-4 h-4 mr-2"
          />
          <label>Save card details</label>
        </div>

        <button className=" text-white font-semibold w-full py-2 px-4 rounded-full mb-4 bg-green-600 hover:bg-green-700">
          Save
        </button>
      </form>

      <p className="text-sm text-gray-500">
        Your personal data will be used to process your order, support your
        experience throughout this website, and for other purposes described in
        our privacy policy.
      </p>
    </div>
  );
};

export default PaymentCard;
