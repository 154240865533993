import React, { useEffect, useState } from "react";
import VideoRecord from "./VideoRecord";
import Header from "./../../../components/header/Header";
import AspectScore from "./aspects/AspectScore";
import { useAnalysisContext } from "../../../context/AnalysisContext";

import css from '../dashboard.module.css'

const Payment = () => {
  const { analysisScores } = useAnalysisContext();
  console.log("🚀 ~ file: Analysis.jsx:11 ~ Payment ~ analysisScores:", analysisScores)
  const [loading, setLaoding] = useState(false);

  //As we have to set Loader here, That's why using Loading here
  //and passing function as props to update isLoading vaue
  const loadingTrue = () => {
    setLaoding(true);
  };

  const loadingFalse = () => {
    setLaoding(false);
  };

  //Some analysis responses are expressions like sad, happy.
  //Here we are converting these into scores
  const mapExpressionToScore = (expression) => {
    if (expression === "happy") {
      return 90;
    } else if (expression === "sad") {
      return 50;
    } else if (expression === "angry") {
      return 40;
    } else if (expression === "normal" || expression === "neutral") {
      return 70;
    }
     else if (expression === "dark") {
      return 40;
    }

    // If the expression is not recognized, return 30 or any default score
    return 30;
  };
  //To convert analysisScores object to array with key and value pair like
  //{body language: '0.0', eye contact: '0.5', face expression: 'neutral', light condition: 'normal'} to
  // [{title: 'body language', score: 0},{title: 'eye contact', score: 50},{..},{..}]
  // const mapAnalysisScoresToArray = (scores) => {
  //   return Object.keys(scores).map((title) => ({
  //     title,
  //     score: !isNaN(parseFloat(scores[title]))
  //       ? scores[title] * 100 //changing to percentage means 0.5 to 50
  //       : mapExpressionToScore(scores[title]),
  //   }));
  // };

  //As we also getting audio_score, extracting that from scores
  const mapAnalysisScoresToArray = (scores) => {
    const scoreEntries = Object.entries(scores);
    return scoreEntries.slice(1).map(([title, score]) => ({
      title,
      score: !isNaN(parseFloat(score)) ? score * 100 : mapExpressionToScore(score),
    }));
  };

  var aspectData;
  if (analysisScores) {
    aspectData = mapAnalysisScoresToArray(analysisScores);
  } else {
    //If No analysis data, assign random data
    aspectData = [
      { score: 95, title: "Hand Posture" },
      { score: 41, title: "Eye Control" },
      { score: 59, title: "Body Control" },
      { score: 75, title: "Lighting" },
      { score: 39, title: "Volume" },
      { score: 67, title: "Pitch" },
      { score: 83, title: "Words" },
      { score: 58, title: "Fluency" },
    ];
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-4 md:p-0">
      <Header title="Do Your Analysis here" size={1.5} border={true} analysis = {true} />
      <div className={css.analysis_wrapper}>
        <h2 className={css.mobile_title}>Do Your Analysis here</h2>
        <div className={css.analysis_card}>
          <VideoRecord loadingTrue={loadingTrue} loadingFalse={loadingFalse} />
        </div>
        <div
          className={css.analysis_result}
        >
          {!loading &&
            aspectData.map((data) => {
              return (
                <AspectScore
                  key={data.title}
                  title={data.title}
                  score={data.score}
                />
              );
            })}

            {/* to make loader vertically center, git it or its wrapper a height */}
            {loading && <div className=" flex justify-center items-center w-full">
              <div className="custom-loader "></div>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Payment;
