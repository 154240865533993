import React, { useState } from "react";

import Frontend from "./categories/Frontend";
import Finance from "./categories/Finance";
import HR from "./categories/HR";
import Graphics from "./categories/Graphics";
import UI from "./categories/UI";
import Backend from "./categories/Backend";
import Motion from "./categories/Motion";
import ProjectManager from "./categories/ProjectManager";
import Marketer from "./categories/Marketer";

//Resouce components
import RFinance from "./../resource/categories/RFinance";
import RFrontend from "./../resource/categories/RFrontend";
import RBackend from "./../resource/categories/RBackend";
import RUI from "./../resource/categories/RUI";
import RHR from './../resource/categories/RHR';
import RGraphics from './../resource/categories/RGraphics';
import RMotion from './../resource/categories/RMotion';
import RMarketer from './../resource/categories/RMarketer';
import RProjectManager from './../resource/categories/RProjectManager';

const CaategoryLinks = ({ resource }) => {
  const [activeLink, setActiveLink] = useState("Finance");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  //As both Resource and Template page, uses the same likns on top, so to render
  //Resource Pages we are using a props "resource" that show that, now on use Resource
  //components instead.




  // NOTE: After adding the Template Card, There is no need to have the
  // templateComponent here. This component is just used for Resource nav list



  const links = [
    {
      resourceComponent: <RFinance />,
      templateComponent: <Finance />,
      label: "Finance",
    },
    {
      resourceComponent: <RHR />,
      templateComponent: <HR />,
      label: "HR",
    },
    {
      resourceComponent: <RGraphics />,
      templateComponent: <Graphics />,
      label: "Graphics",
    },
    {
      resourceComponent: <RUI />,
      templateComponent: <UI />,
      label: "UI Designer",
    },
    {
      resourceComponent: <RFrontend />,
      templateComponent: <Frontend />,
      label: "Frontend",
    },
    {
      resourceComponent: <RBackend />,
      templateComponent: <Backend />,
      label: "Backend ",
    },
    {
      resourceComponent: <RMotion />,
      templateComponent: <Motion />,
      label: "Motion Graphics",
    },
    {
      resourceComponent: <RMarketer />,
      templateComponent: <Marketer />,
      label: "Digital Marketer",
    },
    {
      resourceComponent: <RProjectManager />,
      templateComponent: <ProjectManager />,
      label: "Project Manager",
    },
  ];

  //Here we are setting activeLinkComponent whcih represent the components that is active.
  // if resource props is true, activeLinkComponent = ResourceComponent(that is active) otherwise Template component
  var activeLinkComponent;
  resource
    ? (activeLinkComponent = links.find(
        (link) => link.label === activeLink
      )?.resourceComponent)
    : (activeLinkComponent = links.find(
        (link) => link.label === activeLink
      )?.templateComponent);

  return (
    <nav>
      <ul className=" flex flex-wrap gap-2 my-4">
        {links.map((link) => {
          return (
            <li
              key={link.label}
              className={`inline-block px-4 py-2 rounded-full cursor-pointer ${
                activeLink === link.label ? "bg-yellow-200" : "bg-white"
              }`}
              onClick={() => handleLinkClick(link.label)}
            >
              {link.label}
            </li>
          );
        })}
      </ul>

      {/* {activeLink && <p>{activeLink} Content is here</p>} */}
      {activeLinkComponent && activeLinkComponent}
    </nav>
  );
};

export default CaategoryLinks;
