import React, { useEffect } from "react";
import Header from "./../../components/header/Header";
import CaategoryLinks from "./../templates/CategoryLinks";

import css from './resources.module.css'

const Templates = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header title="Resources" size={1.75} />
      <div className={css.category_links_wrapper}>
      {/* only show this title in small screen, hidden from md: and lg: screen */}
      <h2 className={css.mobile_title}>Resources</h2>
        {/* on each link click, now the components of Resource will render istead
         of template by this props resource = {true}  */}
        <CaategoryLinks resource={true} />
      </div>
    </div>
  );
};

export default Templates;
