import React from "react";

const Analysis = (props) => {
    const {image, text, score} = props
  return (
    <div className="flex justify-between px-2">
      <div className="flex items-center gap-4">
        <img
          className="bg-gray-800 p-2 rounded-lg cursor-pointer"
          src={image}
          alt={text}
        />
        <p className=" font-bold text-lg">{text}</p>
      </div>
      <span className=" font-bold text-lg">{score}%</span>
    </div>
  );
};

export default Analysis;
