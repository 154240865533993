import React, { useState, useEffect } from "react";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";


const VerifyEmail = () => {
  const { user, timeActive, setTimeActive, emailVerification } =
    useUserAuthContext();
  const [time, setTime] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      user
        ?.reload()
        .then(() => {
          if (user?.emailVerified) {
            clearInterval(interval);
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log("Error i VerifyEmail is: ", err.message)
        });
    }, 1000);
  }, [navigate, user]);

  useEffect(() => {
    let interval = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time]);

  const resendEmailVerification = () => {
    emailVerification()
      .then(() => {
        navigate("/verification");
        setTimeActive(true);
      })
      .catch((err) => {
        console.log("Error i VerifyEmail is: ", err.message)
      });
  };

  return (
    <div className=" flex justify-center items-center h-screen bg-gray-700">
      <div className=" shadow-lg p-12 bg-slate-100 rounded-lg">
        <h3 className=" text-3xl text-center mb-4">Verify your Email Address</h3>
        <p >
          A Verification email has been sent to:
          <br />
          <strong>{user?.email}</strong>
        </p>
        <p className="mb-4 mt-4">
          Please follow the instruction in the email to verify your account
        </p>
        <div className=" flex justify-evenly">
          <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300" onClick={()=>{
            navigate('/signup')
          }}>Go Back</button>
          <button
            className={`bg-gray-500 text-white font-bold py-2 px-4 rounded transition duration-300 ${timeActive ? "cursor-not-allowed" : "cursor-pointer hover:bg-gray-700"} `}
            onClick={resendEmailVerification}
            disabled={timeActive}
          >
            Resend Email {timeActive && time}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
