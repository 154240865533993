import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useUserAuthContext } from "./UserAuthContext";
import emailjs from "@emailjs/browser";

// Create the Context
const AnalysisContext = createContext();

// Create a Context Provider component
export const AnalysisContextProvider = ({ children }) => {
  const { user } = useUserAuthContext();
  //As we can use these score throughout our app, that's why setting it to the context
  const [analysisScores, setAnalysisScores] = useState();
  const [jobTitle, setjobTitle] = useState("Software Engineer");
  const [takeInterviewTitle, setTakeInterviewTitle] =
    useState("Software Engineer");
  const [tryPluuoEmail, setTryPluuoEmail] = useState(""); //To get the same email in SignUp page as the user type in Landing page

  const [showScores, setShowScores] = useState(false);

  const [interviewQuestions, setInterviewQuestions] = useState([]);

  const form = useRef();

  const setAnalysisScoresHandler = (response) => {
    setAnalysisScores(response);
  };
  const takeInterviewTitleChange = (title) => {
    setTakeInterviewTitle(title);
  };

  //Show the Score in the /pages/dashboard/analysis/takeanalysis/Result  as we get the response
  useEffect(() => {
    // console.log("Analysis Score in COntext: ", analysisScores);
    if (analysisScores) {
      setShowScores(true);

      emailjs
        .sendForm(
          "service_l0edbkc",
          "template_rwx3zyf",
          form.current,
          "rnwagt2MLDiWJC3Zp"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }, [analysisScores]);

  const handleShowScores = () => {
    setShowScores(false);
  };
  //sending the job title to backend so that the question should
  // be asked according to that title: By default, it is front-end(in the components/header/Header file)
  const sendAnalysisTitle = async ({ interviewTitle }) => {
    setjobTitle(interviewTitle);
    try {
      const response = await fetch(
        // "http://127.0.0.1:5000/junaid",
        // "https://pluuo-backend-fc92c6b72668.herokuapp.com/junaid",
        "https://pluuo-backend-v2-237533y6va-uc.a.run.app/questions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify({ job_title: interviewTitle }),
        }
      );
      if (response.ok) {
        const res = await response.json();
        setInterviewQuestions(res.message);
      } else {
        console.error("Failed to send Interview Title");
      }
    } catch (error) {
      console.error("Error Sending Interview Title: ", error);
    }
  };

  //Use in the Landing page to get the same email in Login Page
  const changeTrypluuoEmail = (email) => {
    setTryPluuoEmail(email);
  };

  return (
    <AnalysisContext.Provider
      value={{
        analysisScores,
        setAnalysisScoresHandler,
        sendAnalysisTitle,
        jobTitle,
        takeInterviewTitle,
        takeInterviewTitleChange,
        tryPluuoEmail,
        changeTrypluuoEmail,
        showScores,
        handleShowScores,
        interviewQuestions,
      }}
    >
      <form ref={form}>
        <input type="hidden" name="email" value={user?.email} />
      </form>
      {children}
    </AnalysisContext.Provider>
  );
};

// Create a custom hook to access the context
export const useAnalysisContext = () => {
  return useContext(AnalysisContext);
};
