import React, { useEffect, useState } from "react";
import css from "./takeAnalysis.module.css";
import { useAnalysisContext } from "../../../../context/AnalysisContext";

const QuestionCard = ({ handleStartRecord }) => {
  const { interviewQuestions } = useAnalysisContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); //Indicates the current question no.
  var progressTime = 30;
  const [timeLeft, setTimeLeft] = useState(progressTime); // Initial time left in seconds
  var prepareTime = 30;
  const [prepareCountdown, setPrepareCountdown] = useState(prepareTime);
  const [showQuestion, setShowQuestion] = useState(false);

  // Countdown Timer of Preparation
  useEffect(() => {
    const interval = setInterval(() => {
      if (prepareCountdown > 0) {
        setPrepareCountdown((prevCountdown) => prevCountdown - 1);
      }
      // Stop the countdown when it reaches 0 and show questions
      if (prepareCountdown === 0) {
        clearInterval(interval);
        setShowQuestion(true);
        handleStartRecord();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [prepareCountdown]);

  //Getting only first 5 questions
  var firstFiveQuestions = interviewQuestions.slice(0, 5);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < firstFiveQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeft(progressTime);
    }
  };

  // Countdown Timer of Question
  useEffect(() => {
    if (interviewQuestions?.length > 0 && prepareCountdown === 0) {
      const interval = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          handleNextQuestion();
        }
      }, 1000);
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [timeLeft, interviewQuestions, prepareCountdown]);

  // Calculate Timeout percentage
  const progress = ((progressTime - timeLeft) / progressTime) * 100;
  const prepareProgress =
    ((prepareTime - prepareCountdown) / prepareTime) * 100;

  return (
    <>
      {prepareCountdown > 0 ? (
        <div className={css.question_card}>
          <p className="text-center text-xl font-semibold mb-2">
            Adjust your camera and position
          </p>
          <p className="text-center mb-4">
            Recording will be starting in: <strong> {prepareCountdown} </strong>
            seconds
          </p>
          <div className={css.progress_container}>
            <div
              className={css.progress_bar}
              style={{ width: `${prepareProgress}%` }}
            ></div>
          </div>
          <div className={css.buttons}>
            <button
              className={
                prepareCountdown > 20 ? css.disabled_button : css.button
              }
              disabled={
                prepareCountdown > 20 //Disable the button when timeLeft > 15 means first 15 sec, you can't skip quesion
              }
              onClick={() => {
                setPrepareCountdown(0);
                setShowQuestion(true);
                handleStartRecord();
              }}
            >
              Skip
            </button>
          </div>
        </div>
      ) : (
        <div className={css.question_card}>
          <h4 className=" text-xl font-semibold mb-4">
            Question <strong>{currentQuestionIndex + 1}</strong>/
            {firstFiveQuestions.length}
          </h4>
          <hr />
          <div className=" flex flex-col items-start justify-between h-5/6 ">
            <h2 className="text-xl font-bold mt-2 md:text-2xl md:mt-4">
              {firstFiveQuestions[currentQuestionIndex]}
            </h2>
            {interviewQuestions?.length === 0 && <p>Generatig Question...</p>}
            <div className={css.question_wrapper}>
              <div className={css.timer}>
                <span className=" text-base font-light">Time to Answer</span>
                <strong>{timeLeft}s</strong>
              </div>
              <div className={css.progress_container}>
                <div
                  className={css.progress_bar}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <hr />

              <div className={css.buttons}>
                <button
                  onClick={handleNextQuestion}
                  disabled={
                    currentQuestionIndex === firstFiveQuestions.length - 1 ||
                    timeLeft > 20 //Disable the button while at last question as well when timeLeft > 15 means first 15 sec, you can't skip quesion
                  }
                  className={
                    currentQuestionIndex === firstFiveQuestions.length - 1 ||
                    timeLeft > 20
                      ? css.disabled_button
                      : css.button
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionCard;
