import React from "react";
import dashboardIcons from "../../../resources";

const VideoScore = ({ title, score }) => {
  var imageSrc = "";
  var status = "";
  var statusColor = "";

  if (score >= 0 && score <= 45) {
    imageSrc = dashboardIcons.percent.poor;
    status = "Needs Imporvements";
    statusColor = "#E37241";
  } else if (score > 45 && score <= 65) {
    imageSrc = dashboardIcons.percent.average;
    status = "Average";
    statusColor = "#E3B641";
  } else if (score > 65 && score <= 85) {
    imageSrc = dashboardIcons.percent.good;
    status = "Good";
    statusColor = "#E0E341";
  } else if (score > 85 && score <= 100) {
    imageSrc = dashboardIcons.percent.excellent;
    status = "Excellent";
    statusColor = "#41E36E";
  }

  return (
    <div className="flex flex-col text-white justify-between p-3 bg-slate-700 rounded-xl shadow-lg font-semibold h-60 w-56">
      <p className=" text-lg">{title}</p>
      <h3 className=" text-2xl">{score}%</h3>
      <div className=" flex gap-2 items-center">
        <img src={imageSrc} alt="Score" className="w-24 h-24" />
        <span style={{ color: statusColor }}>{status}</span>
      </div>
    </div>
  );
};

export default VideoScore;
